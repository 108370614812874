import { SVGIconComponent } from '../types';

export const store: SVGIconComponent = ({ title = '', ...props }) => (
  <svg data-legacy-icon viewBox="0 0 31 22" {...props}>
    <title>{title}</title>
    <path
      d="M22.806 5.069H.263c-.114 0-.229-.083-.229-.166V3.166C.034 3.083.15 3 .264 3h22.542c.114 0 .228.083.228.166v1.737c0 .083-.114.166-.228.166zM24.034.077c0-.039.022-.077.043-.077h5.915c.021 0 .042.038.042.077v21.846c0 .039-.02.077-.042.077h-5.915c-.021 0-.043-.038-.043-.077V.077zM0 6.113C0 6.038.063 6 .19 6h22.62c.127 0 .19.038.19.113v15.774c0 .075-.063.113-.19.113H.19c-.127 0-.19-.038-.19-.113V6.113zM2 12v7h9v-7H2zm10 0v7h9v-7h-9z"
      fillRule="evenodd"
    />
  </svg>
);
