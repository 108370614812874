import { LegacyQueries, RecommendedQueries } from './media-query-types';

export const mediaQuery: RecommendedQueries & LegacyQueries = {
  mobileTiny: 'screen and (max-width: 374px)',
  tablet: 'screen and (min-width: 700px)',
  desktopSmall: 'screen and (min-width: 950px)',
  desktopLarge: 'screen and (min-width: 1200px)',

  desktop: 'screen and (min-width: 813px)',
  mobileSmall: 'screen and (max-width: 670px)',
  mobile: 'screen and (max-width: 812px)',
  mobileLandscape: 'screen and (max-width: 812px) and (orientation: landscape)',
  mobileFullScreen: 'screen and (display-mode: standalone)',
};
