import { FC } from 'react';

import styled from 'styled-components';

import { Box, IBoxProps } from './Box';

export interface IContainerProps
  extends Omit<
    IBoxProps,
    | 'alignSelf'
    | 'flexDirection'
    | 'zIndex'
    | 'position'
    | 'left'
    | 'top'
    | 'height'
    | 'maxHeight'
    | 'bottom'
    | 'right'
    | 'minHeight'
  > {}

export const BaseContainer = styled(Box)<IBoxProps>`
  margin-inline-start: auto;
  margin-inline-end: auto;
`;

export const Container: FC<IContainerProps> = ({
  children,
  center = false,
  show = true,
  align = 'stretch',
  alignContent = 'stretch',
  justify = 'flex-start',
  flexWrap = 'nowrap',
  overflow = 'visible',
  margin = '0',
  padding = '0',
  width = 'auto',
  maxWidth = 'none',
  minWidth = 'none',
  background = 'initial',
  borderWidth = '0',
  borderStyle = 'none',
  borderColor = 'transparent',
  borderRadius = '0',
}) => (
  <BaseContainer
    center={center}
    show={show}
    align={align}
    alignContent={alignContent}
    alignSelf="auto"
    justify={justify}
    flexDirection="column"
    flexWrap={flexWrap}
    overflow={overflow}
    zIndex="auto"
    position="static"
    left="0"
    top="0"
    margin={margin}
    padding={padding}
    width={width}
    height="auto"
    maxWidth={maxWidth}
    maxHeight="none"
    minHeight="none"
    minWidth={minWidth}
    background={background}
    borderWidth={borderWidth}
    borderStyle={borderStyle}
    borderColor={borderColor}
    borderRadius={borderRadius}
  >
    {children}
  </BaseContainer>
);
