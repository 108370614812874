import { IColor } from '../types/color';

export const color: IColor = {
  /* BK: Fiery Red */
  primary: '#D62300',
  /* 10% Darker than Fiery Red */
  secondary: '#A71B00',
  /* BBQ Brown */
  tertiary: '#502314',
  black: '#000000',
  brightWhite: '#FFFFFF',
  /* Mayo Egg White */
  white: '#F5EBDC',
  buttonsDisabledBackground: '#d7c7b5',
  buttonsDisabledLabel: '#9f816f',
  /* 10% Darker than Fiery Red */
  buttonsPrimaryHover: '#a71b00',
  /* 15% of Fiery Red over Mayo Egg White */
  buttonsSecondaryHover: '#F0CDBB',
  /* 25% of Fiery Red over Mayo Egg White */
  buttonsFocused: '#EDB9A5',
  /* BBQ Brown */
  buttonsPrimaryActive: '#502314',
  /* BBQ Brown */
  buttonsSecondaryActive: '#502314',
  inputsFocused: '#AF0C2A',
  /* Crunchy Green */
  statusValidated: '#1B8737',
  /* Fiery Red */
  statusError: '#D62300',
  /* BBQ Brown */
  statusPrice: '#502314',
  /* Flaming Orange */
  statusSuccess: '#FF8732',
  /* Darkest to Lightest */
  100: '#2B0200',
  200: '#340000',
  300: '#4E201D',
  400: '#693F34',
  500: '#845F50',
  600: '#9F816F',
  700: '#BBA391',
  800: '#D7C7B5',
  900: '#F5EBDC',
};
