import styled from 'styled-components';

import { primitive } from '../../designTokens/primitives';

const ChipContainer = styled.div<{ $roundedCorners?: boolean }>`
  background-color: ${(p) => p.theme.token('background-chip')};

  color: ${(p) => p.theme.token('text-reversed')};

  font: ${(p) => p.theme.token('text-style-chip')};

  border-radius: ${(p) => (p.$roundedCorners ? '12px' : p.theme.token('border-radius-chip'))};
  height: ${(p) => p.theme.token('height-chip')};
  width: fit-content;
  min-width: 24px;
  padding: 0 ${primitive.$spacing2};
  display: flex;
  align-items: center;
`;

const ChipText = styled.p<{ $hasIcon?: boolean }>`
  padding-inline-start: ${(p) => (p.$hasIcon ? primitive.$spacing1 : 0)};
`;

export { ChipContainer, ChipText };
