import { DefaultTheme } from 'styled-components';

import { DesignTokens } from '../designTokens';
import { IColor } from '../themes/types/color';

export type ThemeColorArg = keyof DesignTokens | keyof IColor | string;
/**
 * Fetch a color from the current brand theme
 *
 * Fetches the color using the tokenOrColorName to look up colors in this order:
 * 1. Checks design tokens named with tokenOrColorName
 * 2. Checks theme colors with keys named with tokenOrColorName
 * 3. Uses the fallback color string, if one was provided
 */
export const getThemeColor = (
  theme: DefaultTheme | undefined,
  tokenOrColorName: ThemeColorArg,
  fallbackColorString: string | undefined = undefined,
) =>
  theme?.token?.(tokenOrColorName as keyof DesignTokens) ||
  theme?.color?.[tokenOrColorName] ||
  fallbackColorString;
