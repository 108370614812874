import { IFontWeight } from '../types';

export const fontWeight: IFontWeight = {
  headings: 'bold',
  primary: 'normal',
  secondary: '400',
  tertiary: 'normal',
  link: 'normal',
  button: 'normal',
};
