import { SVGIconComponent } from '../types';

export const plkRewards: SVGIconComponent = ({ title = '', ...props }) => (
  <svg viewBox="0 0 24 24" {...props}>
    <title>{title}</title>
    <path
      d="m15.36 7.4582c-1.254-0.21758-3.0621-0.11277-3.697-0.02734l0.2222 2.4783 0.6256-0.04163 0.5223 7.764 2.4097-0.1665-0.1138-1.9046c3.4387-0.4943 3.9326-2.6354 3.8672-4.2741-0.0934-2.3241-1.7368-3.4638-3.8362-3.8281zm-0.2223 5.7449-0.1965-3.294c1.3858 0.06245 1.8504 0.89985 1.8781 1.5946 0.0364 0.9132-0.3219 1.6092-1.6816 1.6994z"
      clipRule="evenodd"
      fillRule="evenodd"
    />
    <path
      d="m21.978 11.977c0.2929 5.0777-2.3625 8.7184-5.6739 9.3243-0.7327 0.134-2.2651 0.1322-2.8899 0.1211-0.4604-0.0081-0.9234-0.0778-1.3812-0.2042-0.1771 0.0615-0.3326 0.1137-0.4506 0.1525-2.925 0.9613-6.9594-0.8917-8.726-4.7955-1.518-3.3545-0.96674-7.0634 1.2493-8.9857 0.39993-0.34693 1.9443-1.6278 3.2023-2.0115 0.3441-0.10496 0.69651-0.17279 1.0536-0.20516 0.62736-0.64591 1.3558-1.1529 2.1626-1.4806 0.5473-0.2223 2.4483-0.94661 3.9135-0.88877 3.7328 0.14734 7.2498 3.9383 7.5403 8.9735zm-14.867-4.8981c-0.91662 1.6644-1.3625 3.7586-1.1582 5.963 0.24048 2.5943 1.3831 4.7998 2.9206 6.2963-1.5929-0.7338-3.002-2.1543-3.7987-4.001-1.4259-3.3053-0.5976-6.7937 2.0363-8.2583zm8.9004 12.779c3.2189-0.4815 5.3214-4.304 4.696-8.5377-0.6254-4.2338-3.7418-7.2756-6.9607-6.794-3.2189 0.48152-5.3214 4.304-4.696 8.5377 0.62539 4.2338 3.7418 7.2756 6.9607 6.794z"
      clipRule="evenodd"
      fillRule="evenodd"
    />
  </svg>
);
