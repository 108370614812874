import { FC } from 'react';

import styled, { DefaultTheme } from 'styled-components';

import { IBaseTextProps, TextVariants } from './types';

const getStyleForVariant = ({ variant, theme }: { variant: TextVariants; theme: DefaultTheme }) => {
  const currentTheme = theme;
  const styles = {
    [TextVariants.H1]: {
      fontSize: currentTheme.fontSize?.headings.H1,
      fontFamily: currentTheme.fontFamily?.primary,
      fontWeight: currentTheme.fontWeight?.headings,
      textTransform: currentTheme.textTransform?.heading,
      letterSpacing: currentTheme.letterSpacing?.headings.H1,
      lineHeight: currentTheme.lineHeight?.heading.LARGE,
    },
    [TextVariants.H2]: {
      fontSize: currentTheme.fontSize?.headings.H2,
      fontFamily: currentTheme.fontFamily?.primary,
      fontWeight: currentTheme.fontWeight?.headings,
      textTransform: currentTheme.textTransform?.heading,
      letterSpacing: currentTheme.letterSpacing?.headings.H2,
      lineHeight: currentTheme.lineHeight?.heading.LARGE,
    },
    [TextVariants.H3]: {
      fontSize: currentTheme.fontSize?.headings.H3,
      fontFamily: currentTheme.fontFamily?.primary,
      fontWeight: currentTheme.fontWeight?.primary,
      textTransform: currentTheme.textTransform?.heading,
      letterSpacing: currentTheme.letterSpacing?.headings.H3,
      lineHeight: currentTheme.lineHeight?.heading.LARGE,
    },
    [TextVariants.H4]: {
      fontSize: currentTheme.fontSize?.headings.H4,
      fontFamily: currentTheme.fontFamily?.primary,
      fontWeight: currentTheme.fontWeight?.primary,
      textTransform: currentTheme.textTransform?.heading,
      letterSpacing: currentTheme.letterSpacing?.headings.H4,
      lineHeight: currentTheme.lineHeight?.heading.SMALL,
    },
    [TextVariants.H5]: {
      fontSize: currentTheme.fontSize?.headings.H5,
      fontFamily: currentTheme.fontFamily?.primary,
      fontWeight: currentTheme.fontWeight?.primary,
      textTransform: currentTheme.textTransform?.heading,
      letterSpacing: currentTheme.letterSpacing?.headings.H5,
      lineHeight: currentTheme.lineHeight?.heading.SMALL,
    },
    [TextVariants.H6]: {
      fontSize: currentTheme.fontSize?.headings.H6,
      fontFamily: currentTheme.fontFamily?.primary,
      fontWeight: currentTheme.fontWeight?.primary,
      textTransform: currentTheme.textTransform?.heading,
      letterSpacing: currentTheme.letterSpacing?.headings.H6,
      lineHeight: currentTheme.lineHeight?.heading.SMALL,
    },
    [TextVariants.subtitle1]: {
      fontSize: currentTheme.fontSize?.subtitle.LARGE,
      fontFamily: currentTheme.fontFamily?.secondary,
      fontWeight: currentTheme.fontWeight?.secondary,
      textTransform: currentTheme.textTransform?.body,
      letterSpacing: currentTheme.letterSpacing?.body,
      lineHeight: currentTheme.lineHeight?.subtitle.LARGE,
    },
    [TextVariants.subtitle2]: {
      fontSize: currentTheme.fontSize?.subtitle.SMALL,
      fontFamily: currentTheme.fontFamily?.secondary,
      fontWeight: currentTheme.fontWeight?.secondary,
      textTransform: currentTheme.textTransform?.body,
      letterSpacing: currentTheme.letterSpacing?.body,
      lineHeight: currentTheme.lineHeight?.subtitle.SMALL,
    },
    [TextVariants.body1]: {
      fontSize: currentTheme.fontSize?.body.LARGE,
      fontFamily: currentTheme.fontFamily?.secondary,
      fontWeight: currentTheme.fontWeight?.tertiary,
      textTransform: currentTheme.textTransform?.body,
      letterSpacing: currentTheme.letterSpacing?.body,
      lineHeight: currentTheme.lineHeight?.body,
    },
    [TextVariants.body2]: {
      fontSize: currentTheme.fontSize?.body.SMALL,
      fontFamily: currentTheme.fontFamily?.secondary,
      fontWeight: currentTheme.fontWeight?.tertiary,
      textTransform: currentTheme.textTransform?.body,
      letterSpacing: currentTheme.letterSpacing?.body,
      lineHeight: currentTheme.lineHeight?.body,
    },
    [TextVariants.caption]: {
      fontSize: currentTheme.fontSize?.caption,
      fontFamily: currentTheme.fontFamily?.secondary,
      fontWeight: currentTheme.fontWeight?.tertiary,
      textTransform: currentTheme.textTransform?.body,
      letterSpacing: currentTheme.letterSpacing?.body,
      lineHeight: currentTheme.lineHeight?.caption,
    },
    [TextVariants.overline]: {
      fontSize: currentTheme.fontSize?.overline,
      fontFamily: currentTheme.fontFamily?.secondary,
      fontWeight: currentTheme.fontWeight?.secondary,
      textTransform: currentTheme.textTransform?.overline,
      letterSpacing: currentTheme.letterSpacing?.overline,
      lineHeight: currentTheme.lineHeight?.overline,
    },
  };
  return styles[variant];
};

const BaseTextStyled = styled.p<IBaseTextProps>`
  font-family: ${(p) => getStyleForVariant(p).fontFamily};
  font-weight: ${(p) => getStyleForVariant(p).fontWeight};
  text-transform: ${(p) => getStyleForVariant(p).textTransform};
  font-size: ${(p) => getStyleForVariant(p).fontSize};
  letter-spacing: ${(p) => getStyleForVariant(p).letterSpacing};
  line-height: ${(p) => getStyleForVariant(p).lineHeight};

  text-align: ${({ align }) => (align ? align : 'left')};
  color: ${({ color, theme }) => (color ? theme.color?.[color] : theme.color?.black || 'black')};
  margin: 0;
  width: 100%;
`;

export const BaseText: FC<IBaseTextProps> = ({ children, color, align, variant, className }) => (
  <BaseTextStyled color={color} align={align} variant={variant} className={className}>
    {children}
  </BaseTextStyled>
);
