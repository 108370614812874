import { SVGIconComponent } from '../types';

export const people: SVGIconComponent = ({ title = '', ...props }) => (
  <svg data-dir="ltr" viewBox="0 0 16 12" {...props}>
    <path
      fillRule="evenodd"
      d="M11.113 6.753c.914.62 1.554 1.46 1.554 2.58v2h2.666v-2c0-1.453-2.38-2.313-4.22-2.58Z"
      clipRule="evenodd"
    />
    <path d="M6 6A2.667 2.667 0 1 0 6 .667 2.667 2.667 0 0 0 6 6Z" />
    <path
      fillRule="evenodd"
      d="M10 6a2.666 2.666 0 1 0 0-5.333c-.313 0-.607.066-.887.16a3.987 3.987 0 0 1 0 5.013c.28.093.574.16.887.16ZM6 6.667c-1.78 0-5.333.893-5.333 2.666v2h10.666v-2C11.333 7.56 7.78 6.667 6 6.667Z"
      clipRule="evenodd"
    />
  </svg>
);
