import { FC, MouseEvent, useCallback, useEffect, useRef } from 'react';

import { PrimaryButton, SecondaryButton, TertiaryButton } from '../button2';

import {
  DialogHeader,
  StyledDialog,
  StyledDialogBody,
  StyledInformationDialog,
  StyledInformationDialogBody,
  StyledOverlay,
} from './dialog.styled';
import { DialogProps, IButton } from './types';

/**
 *
 * @param {object} dialog - Use the useDialogUIState to control the state of the dialog. "const dialog = useDialogUIState()".
 * @param {string} ariaLabel - The label for the dialog that will be read to screen readers.
 * @param {string} headerText - (Only for 1 or more buttons) header text for the dialog.
 * @param {string | {node: ReactNode}} bodyText - The body text of the dialog.
 * @param {IButton} buttons - array of objects containing text, and onClick props for each button.
 * @param {string} closeButtonText - Text for the button that closes the dialog (can also use esc key or click outside dialog).
 * @param {boolean} allButtonsEquallyPreferred - When enabled, switches the first button to a secondary button (only applies for three or more buttons.)
 * @param onDismiss - Optional function to run when closing the dialog.
 */
const Dialog: FC<DialogProps> = ({
  'aria-label': ariaLabel,
  'data-testid': dataTestId,
  bodyText,
  buttons,
  closeButtonText,
  allButtonsEquallyPreferred = false,
  headerText,
  onDismiss,
  dialog,
  className,
}) => {
  /**
   * Triggers onDismiss() when user closes the modal.
   * Necessary as users can close via esc key, clicks outside the dialog, or clicks the close button.
   */
  const shouldRunDismissFn = useRef(false);

  const onRequestedClose = useCallback(() => {
    onDismiss && onDismiss();
    dialog.hide();
  }, [onDismiss, dialog]);

  // Runs onRequestedClose if the dialog is not visible, and if shouldRunDismiss.current is true
  useEffect(() => {
    if (!dialog.visible && shouldRunDismissFn.current) {
      onRequestedClose();
      shouldRunDismissFn.current = false;
    }
  }, [dialog.visible, onRequestedClose, shouldRunDismissFn]);

  // Prevents us from running onRequestedClose() on the initial render
  if (dialog.visible) {
    shouldRunDismissFn.current = true;
  }

  if (!bodyText || !closeButtonText || !ariaLabel) {
    return null;
  }

  if (!buttons?.length) {
    return (
      <StyledOverlay {...dialog}>
        <StyledInformationDialog
          {...dialog}
          data-testid={dataTestId}
          aria-label={ariaLabel}
          className={className}
        >
          <StyledInformationDialogBody>{bodyText}</StyledInformationDialogBody>
          <TertiaryButton onClick={() => dialog.hide()}>{closeButtonText}</TertiaryButton>
        </StyledInformationDialog>
      </StyledOverlay>
    );
  }

  if (!headerText) {
    return null;
  }

  const ButtonComponents =
    buttons?.map((button: IButton, index: number) => {
      const { onClick, text, ...rest } = button;

      function onConfirm(event: MouseEvent<HTMLButtonElement>) {
        // Don't run the onDismiss function when we click one of the confirmation buttons
        shouldRunDismissFn.current = false;
        onClick(event);
        dialog.hide();
      }

      if (index === 0 && (!allButtonsEquallyPreferred || buttons.length < 3)) {
        return (
          <PrimaryButton fullWidth key={index} onClick={onConfirm} {...rest}>
            {text}
          </PrimaryButton>
        );
      }
      return (
        <SecondaryButton fullWidth key={index} onClick={onConfirm} {...rest}>
          {text}
        </SecondaryButton>
      );
    }) || null;

  return (
    <StyledOverlay {...dialog}>
      <StyledDialog
        {...dialog}
        data-testid={dataTestId}
        aria-label={ariaLabel}
        className={className}
      >
        <DialogHeader>{headerText}</DialogHeader>
        <StyledDialogBody>{bodyText}</StyledDialogBody>
        {ButtonComponents}
        <TertiaryButton fullWidth onClick={() => dialog.hide()}>
          {closeButtonText}
        </TertiaryButton>
      </StyledDialog>
    </StyledOverlay>
  );
};

export default Dialog;
