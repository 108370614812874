import { SVGIconComponent } from '../types';

export const profile: SVGIconComponent = ({ title = '', ...props }) => (
  <svg data-legacy-icon viewBox="0 0 22 21" {...props}>
    <title>{title}</title>
    <g id="Profile-icon" stroke="none" strokeWidth="1" fillRule="evenodd">
      <g id="Profile">
        <path
          d="M16,5 C16,7.8 13.8,10 11,10 C8.2,10 6,7.8 6,5 C6,2.2 8.2,0 11,0 C13.8,0 16,2.2 16,5 Z M0,17.1 L0,20.8 L22,20.8 L22,17.1 C22,14.2 19.3,11.8 15.9,11.8 L6.1,11.8 C2.7,11.8 0,14.2 0,17.1 Z"
          id="Shape"
        ></path>
      </g>
    </g>
  </svg>
);
