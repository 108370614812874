import styled, { keyframes } from 'styled-components';

const DURATION = 1.25;
const STAGGER_OFFSET = 0.09375;

const animation = keyframes`
  0% {
    transform: scale(0.5);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  42.5% {
    transform: scale(1) translate3d(var(--translation-direction, 0), 0, 0);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  85% {
    transform: scale(0.5) translate3d(0, 0, 0);
  }
  100% {
    transform: scale(0.5);
  }
`;

export const ParticleAnimation = styled.div`
  background: transparent;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  width: 48px;

  & > span {
    background: currentColor;
    border-radius: 50%;
    display: block;
    height: 10px;
    width: 10px;
    transform-origin: 50% 50%;

    &:nth-child(3n + 1) {
      --translation-direction: -80%;
      animation: ${animation} ${DURATION}s ${-DURATION + 0 * STAGGER_OFFSET}s infinite;
    }

    &:nth-child(3n + 2) {
      animation: ${animation} ${DURATION}s ${-DURATION + 1 * STAGGER_OFFSET}s infinite;
    }

    &:nth-child(3n + 3) {
      --translation-direction: 80%;
      animation: ${animation} ${DURATION}s ${-DURATION + 2 * STAGGER_OFFSET}s infinite;
    }
  }
`;
