import { useEffect, useReducer, useRef } from 'react';

/**
 * Returns `false` until the boolean remains true for a set period of time.
 *
 * Useful for concealing brief activations of variables whose values change
 * often, like the loading state of a piece of UI.
 */
export const usePersistentlyTrueBool = (value: boolean, waitMs: number): boolean => {
  // A ref is used instead of state because we do not want the change in the
  // waited state from `true` to `false` to trigger an unnecessary re-render.
  // The re-render is unnecessary because the return value already evaluates to
  // `false` in the case `value` is `false`
  const stayedTrueRef = useRef(false);
  const [, forceUpdate] = useReducer((x) => x + 1, 0);

  useEffect(() => {
    if (!value) {
      stayedTrueRef.current = false;
      return;
    }

    if (stayedTrueRef.current) {
      return;
    }

    const timeout = setTimeout(() => {
      stayedTrueRef.current = true;
      forceUpdate();
    }, waitMs);

    return () => {
      clearTimeout(timeout);
    };
  }, [stayedTrueRef, value, waitMs]);

  return stayedTrueRef.current && value;
};
