import styled from 'styled-components';

import { getThemeColor } from '../../utils/get-theme-color';

import { IIconWrapperProps } from './types';

export const IconWrapper = styled.div<IIconWrapperProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${(p) => p.width || 'auto'};
  height: ${(p) => p.height || 'auto'};
  svg {
    fill: ${({ theme, color }) => getThemeColor(theme, color, color)};
    stroke: ${({ theme, strokeColor, color }) =>
      strokeColor ? getThemeColor(theme, strokeColor) : getThemeColor(theme, color, color)};
    stroke-width: ${(p) => p.strokeWidth || '0'};
    width: 100%;
    height: 100%;
  }

  &:hover {
    svg {
      ${({ hoverColor, theme }) =>
        hoverColor && {
          fill: getThemeColor(theme, hoverColor),
        }}
    }
  }
`;
