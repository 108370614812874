import { SVGIconComponent } from '../types';

export const mobileNavHomePLK: SVGIconComponent = ({ title = '', ...props }) => (
  <svg data-dir="ltr" viewBox="0 0 24 24" {...props}>
    <title>{title}</title>
    <path
      d="M11.364 11.513s3.735-1.16 2.902-3.64c-.956-2.836-4.493-1.121-4.493-1.121l-.032.015 1.616 4.74.007.006zm1.408 4.431l1.995 5.854L9.102 24 3.972 8.956l-1.826.71L0 3.367l.516-.197 1.75-.682c5.29-2.02 15.668-5.587 18.54 2.937 2.29 6.83-7.967 10.468-7.967 10.468l-.067.05z"
      fillRule="evenodd"
    />
  </svg>
);
