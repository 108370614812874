import { ISpacing } from '../types';

export const spacing: ISpacing = {
  /* 4px */
  100: '0.25rem',
  /* 8px */
  200: '0.5rem',
  /* 12px */
  300: '0.75rem',
  /* 16px */
  400: '1rem',
  /* 24px */
  500: '1.5rem',
  /* 32px */
  600: '2rem',
};
