import { Dialog, DialogBackdrop } from 'reakit/Dialog';
import styled from 'styled-components';

import { primitive } from '../../designTokens/primitives';
import { mediaQuery } from '../../globalStyles';

export const StyledOverlay = styled(DialogBackdrop)`
  display: grid;
  grid-template-columns: 6% auto 6%;
  align-items: center;
  position: fixed;
  inset-block-start: 0;
  inset-inline-start: 0;
  width: 100%;
  height: 100%;
  background-color: ${primitive.$blackOpacity70};
  z-index: 500;
`;

export const StyledDialog = styled(Dialog)`
  display: grid;
  text-align: center;
  margin: auto;
  grid-row-gap: ${primitive.$spacing0};
  grid-column-start: 2;
  padding-block-start: ${primitive.$spacing7};
  padding-block-end: ${primitive.$spacing4};
  padding-inline: ${primitive.$spacing6};
  max-width: 400px;
  background: ${(p) => p.theme.token('background-pattern')};
  border-radius: ${(p) => p.theme.token('border-radius-pattern')};

  @media ${mediaQuery.tablet} {
    padding-block-start: ${primitive.$spacing8};
    padding-block-end: ${primitive.$spacing6};
    padding-inline: ${primitive.$spacing8};
  }
`;

export const StyledInformationDialog = styled(StyledDialog)`
  grid-row-gap: 8px;
  &:nth-last-child(1) {
    justify-items: end;
  }
`;

export const DialogHeader = styled.h2`
  font: ${(p) => p.theme.token('text-style-dialog-header')};
  color: ${(p) => p.theme.token('text-default')};
  padding-block-start: 0;
  padding-block-end: ${primitive.$spacing3};
  padding-inline: 0;
  text-align: start;
  overflow-wrap: anywhere;
  margin: 0;
`;

export const StyledInformationDialogBody = styled.p`
  font: ${(p) => p.theme.token('text-style-dialog-copy')};
  color: ${(p) => p.theme.token('text-default')};
  text-align: start;
  margin: 0;
`;
export const StyledDialogBody = styled(StyledInformationDialogBody)`
  padding-block-start: 0;
  padding-block-end: ${primitive.$spacing5};
  padding-inline: 0;
  margin: 0;

  @media ${mediaQuery.tablet} {
    padding-block-start: 0;
    padding-block-end: ${primitive.$spacing6};
    padding-inline: 0;
  }
`;
