export const toUnit = (unit: string) => (n: number) => `${n}${unit}`;
export const toPx = toUnit('px');

type TCssValue = number | string;
const normalizeXTranslation = (x: TCssValue) => `calc(${x} * var(--x-multiplier))`;

/**
 * The function compensates for the translate function lacking built-in support for RTL layouts.
 * Note: while 'translate3d' could always be used, it would have some impact like pushing the hardware acceleration
 * on some devices, but it also might introduce unwanted sub-pixel blurs. To avoid side effects we'll
 * use the 3d version only where it is meant.
 */
export const normalizedTranslate = (x: TCssValue = 0, y: TCssValue = 0, z?: TCssValue) =>
  z === undefined
    ? `translate(${normalizeXTranslation(x)}, ${y})`
    : `translate3d(${normalizeXTranslation(x)}, ${y}, ${z})`;
