import styled from 'styled-components';

import { Button } from './Button';
import { IButtonProps } from './types';

export const OutlineButton = styled(Button)<IButtonProps>`
  border: 0.0625rem solid
    ${({ theme, disabled }) =>
      disabled
        ? theme.color?.buttonsDisabledBackground
        : theme.token('border-color-button-secondary')};

  background-color: ${({ transparent, theme }) =>
    transparent ? 'transparent' : theme.color?.white};
  color: ${({ theme, disabled }) =>
    disabled ? theme.color?.buttonsDisabledLabel : theme.token('text-button-secondary')};

  div {
    > svg {
      transition: fill 0.125s ease;
      fill: ${({ theme, disabled }) =>
        disabled ? theme.color?.buttonsDisabledLabel : theme.token('icon-button-secondary')};
      stroke: ${({ theme, disabled }) =>
        disabled ? theme.color?.buttonsDisabledLabel : theme.token('icon-button-secondary')};
    }
  }

  &:hover {
    background-color: ${({ theme, disabled, transparent }) => {
      if (disabled) {
        if (transparent) {
          return 'transparent';
        }
        return theme.color?.white;
      }
      return theme.color?.buttonsSecondaryHover;
    }};
  }

  &:active {
    background-color: ${({ theme, disabled, transparent }) => {
      if (disabled) {
        if (transparent) {
          return 'transparent';
        }
        return theme.color?.white;
      }
      return theme.color?.buttonsSecondaryActive;
    }};
    border-color: ${({ theme, disabled, transparent }) => {
      if (disabled) {
        if (transparent) {
          return 'transparent';
        }
        return theme.color?.white;
      }
      return theme.color?.buttonsSecondaryActive;
    }};
    color: ${({ theme, disabled }) =>
      disabled ? theme.color?.buttonsDisabledLabel : theme.color?.white};
    div {
      > svg {
        transition: fill 0.125s ease;
        fill: ${({ theme, disabled }) =>
          disabled ? theme.color?.buttonsDisabledLabel : theme.color?.white};
        stroke: ${({ theme, disabled }) =>
          disabled ? theme.color?.buttonsDisabledLabel : theme.color?.white};
      }
    }
  }
`;

export const ButtonGroup = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-end;
  justify-content: flex-end;

  button {
    margin-inline-end: 1rem;
    &:last-child {
      margin-inline-end: 0;
    }
  }
`;
