import { IFontSize } from '../types';

export const fontSize: IFontSize = {
  headings: {
    H1: '7.5rem',
    H2: '5rem',
    H3: '3.5rem',
    H4: '2rem',
    H5: '1.5rem',
    H6: '1.25rem',
  },

  subtitle: {
    LARGE: '1.125rem',
    SMALL: '1rem',
  },

  body: {
    LARGE: '1rem',
    SMALL: '0.875rem',
  },

  button: {
    LARGE: '1.25rem',
    SMALL: '0.875rem',
  },

  caption: '0.8rem',
  overline: '0.625rem',
  link: '1rem',
};
