import styled from 'styled-components';

import { primitive } from '../../designTokens/primitives';
import { mediaQuery } from '../../globalStyles';
import { getColorWithOpacity } from '../../utils/get-non-transparent-color';
import { buttonAsLinkReset } from '../../utils/styles';

export const Wrapper = styled.div<{ $bgColor?: string; $textColor?: string }>`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  color: ${(p) => p.$textColor || p.theme.token('text-default')};
  background: ${(p) => p.$bgColor || p.theme.token('background-pattern')};
  border-radius: ${(p) => p.theme.token('border-radius-pattern-interactive')};
`;

export const ImageLink = styled.a`
  display: block;
  text-decoration: none;
  color: inherit;
  border-radius: ${(p) => p.theme.token('border-radius-pattern-interactive')};
  border-end-start-radius: 0;
  border-end-end-radius: 0;
  overflow: hidden;

  /* Counters line height produced by inline-block behavior of basic <img> tags */
  line-height: 0;

  & > * {
    width: 100%;
  }
`;

export const TextWrapper = styled.div`
  padding: ${primitive.$spacing4};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-grow: 1;

  @media ${mediaQuery.desktopSmall} {
    padding: ${primitive.$spacing4} ${primitive.$spacing5};

    flex-direction: row;
  }
`;

export const HeaderDescriptionWrapper = styled.div`
  flex: 1 1 auto;
`;

export const ButtonWrapper = styled.div`
  flex: 0 0 auto;
  margin-block-start: ${primitive.$spacing3};
  margin-block-end: 0;
  margin-inline: 0;
  align-self: flex-start;

  @media ${mediaQuery.desktopSmall} {
    align-self: flex-end;
    margin-block: 0;
    margin-inline-start: ${primitive.$spacing4};
    margin-inline-end: 0;
  }
`;

export const Header = styled.div`
  display: flex;
  font: ${(p) => p.theme.token('text-style-card-header')};
  max-width: 100%;
  margin-block-end: ${primitive.$spacing2};
`;

export const Description = styled.div<{ $isDummy?: boolean }>`
  font: ${(p) => p.theme.token('text-style-card-copy')};
`;

export const Terms = styled.button<{ $textColor?: string }>`
  ${buttonAsLinkReset};
  color: ${(p) => p.$textColor || p.theme.token('text-link-default')};

  &:hover {
    color: ${(p) =>
      p.$textColor ? getColorWithOpacity(p.$textColor, 0.8) : p.theme.token('text-link-hover')};
    text-decoration: none;
  }
`;
