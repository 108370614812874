export const getColorComponentsFromString = (hexOrRGB: string) => {
  if (hexOrRGB.startsWith('#')) {
    if (hexOrRGB.length === 4) {
      return {
        r: parseInt(hexOrRGB.substring(1, 2) + hexOrRGB.substring(1, 2), 16),
        g: parseInt(hexOrRGB.substring(2, 3) + hexOrRGB.substring(2, 3), 16),
        b: parseInt(hexOrRGB.substring(3, 4) + hexOrRGB.substring(3, 4), 16),
      };
    }

    if (hexOrRGB.length >= 7) {
      return {
        r: parseInt(hexOrRGB.substring(1, 3), 16),
        g: parseInt(hexOrRGB.substring(3, 5), 16),
        b: parseInt(hexOrRGB.substring(5, 7), 16),
      };
    }
  }

  if (hexOrRGB.startsWith('rgb')) {
    const valuesString = hexOrRGB.match(/rgba?\((.*)\)/i)?.[1];
    if (valuesString) {
      const [r, g, b] = valuesString.split(',').map((v) => parseInt(v, 10));
      return { r, g, b };
    }
  }

  return { r: 0, g: 0, b: 0 };
};

export const getNonTransparentColor = ({
  hexOrRGB,
  hexWhite,
  opacity,
}: {
  hexOrRGB: string;
  hexWhite: string;
  opacity: number;
}) => {
  let { r, g, b } = getColorComponentsFromString(hexOrRGB);

  r = (1 - opacity) * parseInt(hexWhite.substring(1, 3), 16) + opacity * r;
  g = (1 - opacity) * parseInt(hexWhite.substring(3, 5), 16) + opacity * g;
  b = (1 - opacity) * parseInt(hexWhite.substring(5, 7), 16) + opacity * b;

  return `rgb(${r},${g},${b})`;
};

export const getColorWithOpacity = (hexOrRGB: string, opacity: number) => {
  const { r, g, b } = getColorComponentsFromString(hexOrRGB);

  return `rgba(${r},${g},${b},${opacity})`;
};

/**
 * Determine if a color appears dark to humans
 * Adapted from {@link http://alienryderflex.com/hsp.html}
 */
export const isColorDark = (hexOrRGB: string) => {
  const { r, g, b } = getColorComponentsFromString(hexOrRGB);

  const hsp = 0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b);
  return hsp < 16256;
};
