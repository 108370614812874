import { StyledFlex, StyledFlexItem, StyledFlexSpacer } from './flex.styled';

/**
 * Provides Flexbox utilities for JSX
 */
export const Flex = StyledFlex;

/**
 * Provides Flexbox item utilities for JSX
 */
export const FlexItem = StyledFlexItem;

/**
 * Fills space in between Flex Items.
 *
 * uses flex: auto
 */
export const FlexSpacer = StyledFlexSpacer;

export default Flex;
