import { FC } from 'react';

import styled from 'styled-components';

import { mediaQuery } from '../..';

import { IFlexItemProps, IFlexItemSpacerProps, IFlexProps } from './types';

export const StyledFlex = styled.div<IFlexProps>`
  display: flex;

  ${(props) => ({
    flexDirection: props.flexDirection,
    flexWrap: props.flexWrap,
    justifyContent: props.justifyContent,
    justifyItems: props.justifyItems,
    alignContent: props.alignContent,
    alignItems: props.alignItems,
  })}

  @media ${mediaQuery.mobileTiny} {
    ${(props) => ({
      flexDirection: props.flexDirectionMobileTiny,
      flexWrap: props.flexWrapMobileTiny,
      justifyContent: props.justifyContentMobileTiny,
      justifyItems: props.justifyItemsMobileTiny,
      alignContent: props.alignContentMobileTiny,
      alignItems: props.alignItemsMobileTiny,
    })}
  }

  @media ${mediaQuery.tablet} {
    ${(props) => ({
      flexDirection: props.flexDirectionTablet,
      flexWrap: props.flexWrapTablet,
      justifyContent: props.justifyContentTablet,
      justifyItems: props.justifyItemsTablet,
      alignContent: props.alignContentTablet,
      alignItems: props.alignItemsTablet,
    })}
  }

  @media ${mediaQuery.desktopSmall} {
    ${(props) => ({
      flexDirection: props.flexDirectionDesktopSmall,
      flexWrap: props.flexWrapDesktopSmall,
      justifyContent: props.justifyContentDesktopSmall,
      justifyItems: props.justifyItemsDesktopSmall,
      alignContent: props.alignContentDesktopSmall,
      alignItems: props.alignItemsDesktopSmall,
    })}
  }

  @media ${mediaQuery.desktopLarge} {
    ${(props) => ({
      flexDirection: props.flexDirectionDesktopLarge,
      flexWrap: props.flexWrapDesktopLarge,
      justifyContent: props.justifyContentDesktopLarge,
      justifyItems: props.justifyItemsDesktopLarge,
      alignContent: props.alignContentDesktopLarge,
      alignItems: props.alignItemsDesktopLarge,
    })}
  }
` as FC<IFlexProps>;

export const StyledFlexItem = styled.div<IFlexItemProps>`
  ${(props) => ({
    flex: props.flex,
    order: props.order,
    flexGrow: props.flexGrow,
    flexShrink: props.flexShrink,
    flexBasis: props.flexBasis,
    alignSelf: props.alignSelf,
    justifySelf: props.justifySelf,
  })}

  @media ${mediaQuery.mobileTiny} {
    ${(props) => ({
      flex: props.flexMobileTiny,
      order: props.orderMobileTiny,
      flexGrow: props.flexGrowMobileTiny,
      flexShrink: props.flexShrinkMobileTiny,
      flexBasis: props.flexBasisMobileTiny,
      alignSelf: props.alignSelfMobileTiny,
      justifySelf: props.justifySelfMobileTiny,
    })}
  }

  @media ${mediaQuery.tablet} {
    ${(props) => ({
      flex: props.flexTablet,
      order: props.orderTablet,
      flexGrow: props.flexGrowTablet,
      flexShrink: props.flexShrinkTablet,
      flexBasis: props.flexBasisTablet,
      alignSelf: props.alignSelfTablet,
      justifySelf: props.justifySelfTablet,
    })}
  }

  @media ${mediaQuery.desktopSmall} {
    ${(props) => ({
      flex: props.flexDesktopSmall,
      order: props.orderDesktopSmall,
      flexGrow: props.flexGrowDesktopSmall,
      flexShrink: props.flexShrinkDesktopSmall,
      flexBasis: props.flexBasisDesktopSmall,
      alignSelf: props.alignSelfDesktopSmall,
      justifySelf: props.justifySelfDesktopSmall,
    })}
  }

  @media ${mediaQuery.desktopLarge} {
    ${(props) => ({
      flex: props.flexDesktopLarge,
      order: props.orderDesktopLarge,
      flexGrow: props.flexGrowDesktopLarge,
      flexShrink: props.flexShrinkDesktopLarge,
      flexBasis: props.flexBasisDesktopLarge,
      alignSelf: props.alignSelfDesktopLarge,
      justifySelf: props.justifySelfDesktopLarge,
    })}
  }
` as FC<IFlexItemProps>;

export const StyledFlexSpacer = styled.span<IFlexItemSpacerProps>`
  display: block;
  flex: auto;
`;
