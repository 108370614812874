import { Ref, forwardRef } from 'react';

import { TheEveryButton, makeIconOnlyButton } from './the-every-button';
import {
  PrimaryButtonFn,
  PrimaryButtonProps,
  SecondaryButtonFn,
  SecondaryButtonProps,
  TertiaryButtonFn,
  TertiaryButtonProps,
} from './types';

export const PrimaryButton: PrimaryButtonFn = forwardRef<
  HTMLButtonElement | HTMLAnchorElement,
  PrimaryButtonProps
>((props, forwardedRef) => (
  <TheEveryButton {...props} variant="primary" ref={forwardedRef as Ref<HTMLButtonElement>} />
));
export const SecondaryButton: SecondaryButtonFn = forwardRef<
  HTMLButtonElement | HTMLAnchorElement,
  SecondaryButtonProps
>((props, forwardedRef) => (
  <TheEveryButton {...props} variant="secondary" ref={forwardedRef as Ref<HTMLButtonElement>} />
));
export const TertiaryButton: TertiaryButtonFn = forwardRef<
  HTMLButtonElement | HTMLAnchorElement,
  TertiaryButtonProps
>((props, forwardedRef) => (
  <TheEveryButton {...props} variant="tertiary" ref={forwardedRef as Ref<HTMLButtonElement>} />
));

export const PreviousButton = makeIconOnlyButton({ icon: 'previous' });
export const NextButton = makeIconOnlyButton({ icon: 'next' });
