import { SVGIconComponent } from '../types';

export const dineIn: SVGIconComponent = ({ title = '', ...props }) => (
  <svg
    data-dir="ltr"
    data-legacy-icon
    viewBox="0 0 106 67"
    fillRule="evenodd"
    clipRule="evenodd"
    {...props}
  >
    <title>{title}</title>
    <circle cx="55" cy="34" r="30" />
    <path
      d="M99,45.2V64c0,1.7-1.3,3-3,3s-3-1.3-3-3V4c0-0.3,0-0.6,0.1-0.8L93,1.6C93,0.7,93.7,0,94.6,0h0.7
	c7,0,10.7,21.9,10.7,43.6c0,0.9-0.7,1.6-1.6,1.6H99z"
    />
    <path
      d="M14,14.7V16c0,0.6,0.4,1,1,1s1-0.4,1-1V4c0-1.7,1.3-3,3-3s3,1.3,3,3v13c0,4.1-3.6,8.2-8,9.5V64c0,1.7-1.3,3-3,3
	s-3-1.3-3-3V26.4c-4.6-1.2-8-6-8-9.3L0,4c0-1.7,1.3-3,3-3s3,1.3,3,3v12c0,0.6,0.4,1,1,1s1-0.4,1-1v-1.4V4c0-1.7,1.3-3,3-3s3,1.3,3,3
	V14.7z"
    />
  </svg>
);
