import { createGlobalStyle } from 'styled-components';

import { primitive } from '../../designTokens/primitives';
import { rootVars } from '../base';
import { mediaQuery } from '../mediaQueries';

export const GlobalStyles = createGlobalStyle`
  ${rootVars}
  html {
    --font-hero: ${primitive.bk.$hero};
    --font-headerOne: ${primitive.bk.$headerOne};
    --font-headerTwo: ${primitive.bk.$headerTwo};
    --font-headerThree: ${primitive.bk.$headerThree};
    --font-copyOne: ${primitive.bk.$copyOne};
    --font-copyTwo: ${primitive.bk.$copyTwo};

    @media ${mediaQuery.desktopSmall} {
      --font-headerOne: ${primitive.bk.$headerOneDesktop};
      --font-headerTwo: ${primitive.bk.$headerTwoDesktop};
      --font-headerThree: ${primitive.bk.$headerThreeDesktop};
      --font-copyOne: ${primitive.bk.$copyOneDesktop};
      --font-copyTwo: ${primitive.bk.$copyTwoDesktop};
    }

    @media ${mediaQuery.desktopLarge} {
      --font-hero: ${primitive.bk.$heroDesktop};
    }
  }
`;
