import { css } from 'styled-components';

export const rootVars = css`
  :root,
  *[dir='ltr'] {
    --x-multiplier: 1;
  }

  *[dir='rtl'] {
    --x-multiplier: -1;
  }

  *[dir]:not([dir='ltr']) svg[data-dir='ltr'] {
    transform: scaleX(1);
  }

  *[dir='rtl'] svg,
  svg[data-dir='rtl'] {
    transform: scaleX(-1);
  }
`;
