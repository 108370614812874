import { SVGIconComponent } from '../types';

export const storeTwo: SVGIconComponent = ({ title = '', ...props }) => (
  <svg data-legacy-icon viewBox="0 0 26 21" {...props}>
    <title>{title}</title>
    <g fillRule="evenodd">
      <rect width={26} height={4} rx={0.5} />
      <path d="M2.5 5h21a.5.5 0 0 1 .5.5v15a.5.5 0 0 1-.5.5h-21a.5.5 0 0 1-.5-.5v-15a.5.5 0 0 1 .5-.5zm2 3a.5.5 0 0 0-.5.5v8a.5.5 0 0 0 .5.5h7a.5.5 0 0 0 .5-.5v-8a.5.5 0 0 0-.5-.5h-7zm10 0a.5.5 0 0 0-.5.5v8a.5.5 0 0 0 .5.5h7a.5.5 0 0 0 .5-.5v-8a.5.5 0 0 0-.5-.5h-7z" />
    </g>
  </svg>
);
