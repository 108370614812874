import { FC } from 'react';

import { useTheme } from 'styled-components';

import { Icon } from '../icon';

import { ChipContainer, ChipText } from './chip.styled';
import { ChipProps } from './types';

const Chip: FC<ChipProps> = ({
  children,
  icon: iconProp,
  roundedCorners,
  'data-testid': dataTestId,
  'aria-label': ariaLabel,
  className,
}) => {
  const { token } = useTheme();

  let icon = null;
  if (iconProp && !roundedCorners) {
    icon = (
      <Icon
        icon={iconProp}
        color="icon-reversed"
        aria-hidden
        width={token('width-icon-small')}
        height={token('height-icon-small')}
        data-testid={dataTestId !== undefined ? `${dataTestId}-icon` : undefined}
      />
    );
  }

  return (
    <ChipContainer
      $roundedCorners={roundedCorners}
      data-testid={dataTestId}
      aria-label={ariaLabel}
      className={className}
    >
      {icon}
      <ChipText $hasIcon={!!icon}>{children}</ChipText>
    </ChipContainer>
  );
};

export default Chip;
