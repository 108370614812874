import { SVGIconComponent } from '../types';

export const twitter: SVGIconComponent = ({ title = '', ...props }) => (
  <svg data-dir="ltr" viewBox="0 0 15 12" {...props}>
    <title>{title}</title>
    <path
      d="M 7.140625 5.078125 L 11.609375 0 L 10.550781 0 L 6.671875 4.410156 L 3.574219 0 L 0 0 L 4.683594 6.667969 L 0 11.992188 L 1.058594 11.992188 L 5.15625 7.335938 L 8.425781 11.992188 L 12 11.992188 Z M 5.691406 6.726562 L 5.21875 6.0625 L 1.441406 0.78125 L 3.066406 0.78125 L 6.113281 5.042969 L 6.589844 5.707031 L 10.550781 11.25 L 8.925781 11.25 Z M 5.691406 6.726562 "
      fillRule="evenodd"
    />
  </svg>
);
