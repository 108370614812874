import { SVGIconComponent } from '../types';

export const paypal: SVGIconComponent = ({ title = '', ...props }) => (
  <svg data-dir="ltr" viewBox="0 0 16.95 20" {...props}>
    <title>{title}</title>
    <path
      fill="#8C8C8C"
      d="M15.28 5.08c0 .12 0 .24-.07.37-.82 4.2-3.62 5.66-7.21 5.66H6.18a.87.87 0 0 0-.87.75l-.94 5.92-.26 1.68a.46.46 0 0 0 .46.54H7.8a.78.78 0 0 0 .77-.66v-.16l.61-3.87v-.21a.78.78 0 0 1 .77-.66h.48c3.14 0 5.59-1.27 6.31-4.95a4.24 4.24 0 0 0-.65-3.73 3.33 3.33 0 0 0-.81-.68z"
    />
    <path
      fill="#212121"
      d="M14.42 4.74l-.42-.1-.41-.08A10.1 10.1 0 0 0 12 4.45H7.14a.8.8 0 0 0-.34.07.79.79 0 0 0-.43.58l-1 6.57v.19a.87.87 0 0 1 .87-.75H8c3.59 0 6.39-1.46 7.21-5.66 0-.13 0-.25.07-.37a4.61 4.61 0 0 0-.68-.28z"
    />
    <path
      fill="#2F2F2F"
      d="M6.37 5.1a.79.79 0 0 1 .43-.58.8.8 0 0 1 .34-.07H12a10.1 10.1 0 0 1 1.61.11l.41.08.39.1.18.06a4.61 4.61 0 0 1 .68.28 4 4 0 0 0-.85-3.57C13.51.45 11.83 0 9.69 0H3.47a.88.88 0 0 0-.87.75L0 17.16a.53.53 0 0 0 .52.62h3.85l1-6.11z"
    />
  </svg>
);
