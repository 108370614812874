import { IFontFace, SupportedFontTypes, SupportedFonts } from '../types';

export const fontFace: IFontFace[] = [
  {
    name: 'Flame Bold',
    sources: [{ source: SupportedFonts.FlameBold, type: SupportedFontTypes.woff2 }],
    style: 'normal',
    weight: 'normal',
    display: 'swap',
  },
  {
    name: 'Flame',
    sources: [{ source: SupportedFonts.FlameRegular, type: SupportedFontTypes.woff2 }],
    style: 'normal',
    weight: 'normal',
    display: 'swap',
  },
  {
    name: 'Flame Sans',
    sources: [{ source: SupportedFonts.FlameSans, type: SupportedFontTypes.woff2 }],
    style: 'normal',
    weight: 'normal',
    display: 'swap',
  },
];
