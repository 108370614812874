import { SVGIconComponent } from '../types';

export const gear: SVGIconComponent = ({ title = '', ...props }) => (
  <svg data-legacy-icon viewBox="0 0 19 20" {...props}>
    <title>{title}</title>
    <path
      d="M11.323.685l.314 2.046c1.22.342 2.327.971 3.235 1.826l1.972-.757c.357-.137.773-.06.975.28l1.086 1.84c.202.342.062.732-.237.964l-1.657 1.29a7.426 7.426 0 0 1 0 3.653l1.657 1.289c.299.232.44.622.237.964l-1.086 1.84c-.202.34-.618.417-.975.28l-1.972-.757a7.777 7.777 0 0 1-3.235 1.826l-.314 2.046c-.057.371-.333.685-.737.685H8.414c-.404 0-.68-.314-.737-.685l-.314-2.046a7.762 7.762 0 0 1-3.235-1.826l-1.972.757c-.357.137-.773.06-.975-.28L.095 14.08c-.202-.342-.062-.732.237-.964l1.657-1.29a7.426 7.426 0 0 1 0-3.653L.332 6.884c-.299-.232-.44-.622-.237-.964L1.18 4.08c.1-.169.254-.273.428-.32a.906.906 0 0 1 .547.04l1.972.757a7.762 7.762 0 0 1 3.235-1.826L7.677.685C7.734.314 8.01 0 8.414 0h2.172c.404 0 .68.314.737.685zm.982 6.573c-1.55-1.514-4.062-1.514-5.61 0a3.815 3.815 0 0 0 0 5.484c1.548 1.514 4.06 1.514 5.61 0a3.815 3.815 0 0 0 0-5.484z"
      fillRule="nonzero"
    />
  </svg>
);
