import { DefaultTheme, useTheme } from 'styled-components';

import * as Svgs from '../../svgs';
import { SVGIconComponent } from '../../svgs/types';
import { IIcon } from '../../themes';

export const useThemeIcon = function (icon: keyof IIcon) {
  const theme: DefaultTheme | undefined = useTheme();

  try {
    const ThemeIcon: SVGIconComponent | undefined = Svgs[theme.icon?.[icon]];
    return ThemeIcon;
  } catch {
    if (process.env.NODE_ENV === 'development') {
      console.warn('useThemeIcon must be used within a ThemeProvider');
    }
    return undefined;
  }
};
