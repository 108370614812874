import { FC, ReactNode } from 'react';

import styled from 'styled-components';

/* possibly add min height and width as p */

/* ADD TRANSFORM, FLEX, AND ANIMATIONS AS PROPS */
export interface IBoxProps {
  //display
  show?: boolean;
  //placement
  center?: boolean;
  align?: string;
  alignContent?: string;
  alignSelf?: string;
  justify?: string;
  flexDirection?: string;
  //content placement
  flexWrap?: string;
  overflow?: string;
  //location
  zIndex?: string;
  position?: string;
  left?: string;
  top?: string;
  bottom?: string;
  right?: string;
  //spacing
  margin?: string;
  padding?: string;
  //sizing
  width?: string;
  height?: string;
  maxWidth?: string;
  maxHeight?: string;
  minWidth?: string;
  minHeight?: string;
  //styling
  background?: string;
  borderWidth?: string;
  borderStyle?: string;
  borderColor?: string;
  borderRadius?: string;
  boxShadow?: string;
  children?: ReactNode;
}

/**
 * TEMP FIX (until we remove the component)
 *
 * Box component (deprecated) is still widely used in white-label app
 * Since we are adding right-to-left capabilities we cannot use margin|padding shorthands with 4 values
 * as they imply directionality.
 * This temporary fix add some functionality at runtime to convert those properties to the logical counterparts
 * Ideally in the future this component will not be used anymore. The same treatment should be applied to other
 * properties like left/right but there are no occurrences of this component that use them at the time of writing
 *
 * https://rbictg.atlassian.net/wiki/spaces/ICFE/pages/4363976710/CSS+Logical+Properties
 */
const SPACE = ' ';

type TPropertyObject = Record<string, string>;
type TCssProperty = string;
type TCssValues = string[];
type TExpandingFunction = (cssProperty: TCssProperty, values: TCssValues) => TPropertyObject;
type TValuesExpander = (properties: string[]) => TExpandingFunction;

const toSubPropertyObject =
  (cssProperty: TCssProperty, values: TCssValues) =>
  (acc: TPropertyObject, subProperty: string, index: number) => ({
    ...acc,
    [`${cssProperty}-${subProperty}`]: values[index],
  });

// (1 and 2)-values shorthands are kept as is
const keep: TExpandingFunction = (cssProperty: string, values) => ({
  [cssProperty]: values.join(SPACE),
});

const expand3Properties = ['block-start', 'inline', 'block-end'];
const expand4Properties = ['block-start', 'inline-end', 'block-end', 'inline-start'];
const expandValues: TValuesExpander = (subProperties) => (cssProperty, values) =>
  subProperties.reduce(toSubPropertyObject(cssProperty, values), {});

const valuesLengthMappingFunction = [
  keep,
  keep,
  expandValues(expand3Properties),
  expandValues(expand4Properties),
];

const getExpandedProperty = (cssProperty: string, value: string | undefined) => {
  const values = value?.trim().split(/\s+/);

  return values?.length ? valuesLengthMappingFunction[values.length - 1](cssProperty, values) : {};
};

const expandableProperties = ['margin', 'padding'];
const toPropertiesObject = (p: IBoxProps) => (acc: TPropertyObject, cssProperty: string) => ({
  ...acc,
  ...getExpandedProperty(cssProperty, p[cssProperty]),
});

export const BaseBox = styled.div<IBoxProps>`
  ${(p) => expandableProperties.reduce(toPropertiesObject(p), {})}
  display: ${(p) => (p.show ? 'flex' : 'none')};

  align-items: ${(p) => (p.center ? 'center' : p.align)};
  align-content: ${(p) => (p.center ? 'center' : p.alignContent)};
  align-self: ${(p) => p.alignSelf};
  justify-content: ${(p) => (p.center ? 'center' : p.justify)};
  flex-direction: ${(p) => p.flexDirection};

  flex-wrap: ${(p) => p.flexWrap};
  overflow: ${(p) => p.overflow};

  position: ${(p) => p.position};
  z-index: ${(p) => p.zIndex};
  inset-inline-start: ${(p) => p.left};
  inset-block-start: ${(p) => p.top};
  inset-block-end: ${(p) => p.bottom};
  inset-inline-end: ${(p) => p.right};

  width: ${(p) => p.width};
  height: ${(p) => p.height};
  max-width: ${(p) => p.maxWidth};
  max-height: ${(p) => p.maxHeight};
  min-width: ${(p) => p.minWidth};
  min-height: ${(p) => p.minHeight};
  background: ${({ theme, background }) =>
    background ? theme.color?.[background] || background : 'transparent'};
  border-width: ${(p) => p.borderWidth};
  border-style: ${(p) => p.borderStyle};
  border-color: ${(p) => p.borderColor};
  border-radius: ${(p) => p.borderRadius};
  box-shadow: ${(p) => p.boxShadow};
`;

export const Box: FC<IBoxProps> = ({
  children,
  center = false,
  show = true,
  align = 'stretch',
  alignContent = 'stretch',
  alignSelf = 'auto',
  justify = 'flex-start',
  flexDirection = 'row',
  flexWrap = 'nowrap',
  overflow = 'visible',
  zIndex = 'auto',
  position = 'static',
  left = 'auto',
  top = 'auto',
  bottom = 'auto',
  right = 'auto',
  margin = '0',
  padding = '0',
  width = 'auto',
  height = 'auto',
  maxWidth = 'none',
  maxHeight = 'none',
  minWidth = '0',
  minHeight = '0',
  background = 'initial',
  borderWidth = '0',
  borderStyle = 'none',
  borderColor = 'transparent',
  borderRadius = '0',
  boxShadow = 'none',
}) => (
  <BaseBox
    center={center}
    show={show}
    align={align}
    alignContent={alignContent}
    alignSelf={alignSelf}
    justify={justify}
    flexDirection={flexDirection}
    flexWrap={flexWrap}
    overflow={overflow}
    zIndex={zIndex}
    position={position}
    left={left}
    top={top}
    bottom={bottom}
    right={right}
    margin={margin}
    padding={padding}
    width={width}
    height={height}
    maxWidth={maxWidth}
    maxHeight={maxHeight}
    minWidth={minWidth}
    minHeight={minHeight}
    background={background}
    borderWidth={borderWidth}
    borderStyle={borderStyle}
    borderColor={borderColor}
    borderRadius={borderRadius}
    boxShadow={boxShadow}
  >
    {children}
  </BaseBox>
);
