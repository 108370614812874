import { useIdCounter, useMediaQuery } from '../../hooks';
import { isColorDark } from '../../utils/get-non-transparent-color';
import { PrimaryButton, SecondaryButton } from '../button2';
import { VisuallyHidden } from '../visually-hidden';

import {
  ButtonWrapper,
  Description,
  Header,
  HeaderDescriptionWrapper,
  ImageLink,
  Terms,
  TextWrapper,
  Wrapper,
} from './card.styled';
import { CardProps } from './types';

/**
 * Displays a link comprised of an image and text, used for marketing
 */
const Card = ({
  image,
  imageAlt,
  header,
  description,
  callToAction,
  href,
  linkAs,
  termsLinkText,
  termsLinkHref,
  termsLinkAs,
  marketingId,
  variant = 'default',
  bgColor,
  textColor,
  buttonBgColor,
  buttonTextColor,
  onClick,
  onClickTermsLink,
  className,
}: CardProps) => {
  const isDesktop = useMediaQuery('desktopSmall');
  const id = useIdCounter('card');
  const allCopyId = `${id}-all-copy`;

  const onMainLinkClick = (event: React.MouseEvent<HTMLAnchorElement>) =>
    onClick?.(event, { marketingId });

  const ButtonComponent = variant === 'default' ? SecondaryButton : PrimaryButton;
  const buttonProps = {
    onClick: onMainLinkClick,
    size: isDesktop ? 'large' : 'small',
    'aria-describedby': allCopyId,
    ...(variant === 'campaign' && {
      bgColor: buttonBgColor,
      textColor: buttonTextColor,
      reversed: !!bgColor && isColorDark(bgColor),
    }),
  } as const;
  const colorTweaks = variant === 'campaign' ? { $textColor: textColor, $bgColor: bgColor } : {};

  return (
    <Wrapper {...colorTweaks} className={className}>
      <ImageLink
        as={linkAs || 'a'}
        {...(linkAs ? { to: href } : { href })}
        onClick={onMainLinkClick}
        tabIndex={-1}
        // We make this hidden to screen readers because the same link is
        // accessible via the button below, and then image alt text, passed in
        // as a prop, is made available with VisuallyHidden text
        aria-hidden
      >
        {image}
      </ImageLink>
      <TextWrapper>
        <HeaderDescriptionWrapper id={allCopyId}>
          {imageAlt && <VisuallyHidden>{imageAlt}</VisuallyHidden>}
          <Header>{header}</Header>
          <Description>
            {description}
            {termsLinkText && (
              <>
                {' '}
                <Terms
                  {...colorTweaks}
                  onClick={(
                    event:
                      | React.MouseEvent<HTMLButtonElement>
                      | React.MouseEvent<HTMLAnchorElement>,
                  ) => onClickTermsLink?.(event, { marketingId })}
                  {...(termsLinkHref && {
                    as: termsLinkAs || 'a',
                    ...(termsLinkAs ? { to: termsLinkHref } : { href: termsLinkHref }),
                  })}
                  aria-describedby={allCopyId}
                >
                  {termsLinkText}
                </Terms>
              </>
            )}
          </Description>
        </HeaderDescriptionWrapper>

        <ButtonWrapper>
          {linkAs ? (
            <ButtonComponent {...buttonProps} as={linkAs} to={href}>
              {callToAction}
            </ButtonComponent>
          ) : (
            <ButtonComponent as="a" {...buttonProps} href={href}>
              {callToAction}
            </ButtonComponent>
          )}
        </ButtonWrapper>
      </TextWrapper>
    </Wrapper>
  );
};

export default Card;
