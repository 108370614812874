import { useEffect, useState } from 'react';

import { LegacyQueries, RecommendedQueries } from '../../globalStyles/media-query-types';
import { mediaQuery } from '../../globalStyles/mediaQueries';

/**
 * Returns `true` if the current screen matches the given media query.
 *
 * It will also watch the screen for dimension changes and update if the
 * matching state changes.
 * Based on {@link https://www.netlify.com/blog/2020/12/05/building-a-custom-react-media-query-hook-for-more-responsive-apps/}
 *
 * @param queryName The name of an existing media query found on the
 * {@link mediaQuery} object, or an object of shape `{ custom: string }`
 * containing a custom query
 *
 * @returns `true` if the screen matches the query, `false` if not
 */
export const useMediaQuery = (
  queryName: keyof RecommendedQueries | keyof LegacyQueries,
): boolean => {
  const query = mediaQuery[queryName];

  const [isMatching, setIsMatching] = useState(false);

  useEffect(() => {
    const mediaQueryList = window.matchMedia(query);

    if (isMatching !== mediaQueryList.matches) {
      setIsMatching(mediaQueryList.matches);
    }

    const mediaListener = () => {
      setIsMatching(mediaQueryList.matches);
    };

    mediaQueryList.addEventListener('change', mediaListener);

    return () => {
      mediaQueryList.removeEventListener('change', mediaListener);
    };
  }, [query, isMatching]);

  return isMatching;
};
