import { HTMLProps, KeyboardEvent, MouseEvent } from 'react';

import { ThemeColorArg } from '../../utils/get-theme-color';

export enum ButtonSizes {
  SMALL = 'small',
  LARGE = 'large',
}

export type OnClick = (
  event: MouseEvent<HTMLButtonElement> | KeyboardEvent<HTMLButtonElement>,
) => void;

export interface IBaseButtonProps extends Omit<HTMLProps<HTMLButtonElement>, 'size' | 'as'> {
  disabled?: boolean;
  size?: ButtonSizes;
  onClick?: OnClick;
  hasStartIcon?: boolean;
  hasEndIcon?: boolean;
  onlyIcon?: boolean;
  fullWidth?: boolean;
  isSquare?: boolean;
  as?: React.ElementType | keyof JSX.IntrinsicElements;
  transparent?: boolean;
  wrapText?: boolean;
}

export interface IButtonProps extends Omit<IBaseButtonProps, 'hasEndIcon' | 'hasStartIcon'> {
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
}

export interface IScanButtonProps extends IButtonProps {
  endIcon: React.ReactNode;
  title: string;
  description: string;
}

export interface IBoxShadowButtonProps extends IButtonProps {
  inverse?: boolean;
}

export interface IBaseInverseButtonProps extends IButtonProps {
  color: ThemeColorArg;
}
export interface IInverseButtonProps extends Omit<IBaseInverseButtonProps, 'color'> {
  color?: ThemeColorArg;
}

export interface ITextOnlyButtonProps extends IButtonProps {
  color?: ThemeColorArg;
  transformText?: string;
}
