import { ILineHeight } from '../types';

export const lineHeight: ILineHeight = {
  heading: {
    LARGE: '0.9',
    SMALL: '1',
  },

  subtitle: {
    LARGE: '1',
    SMALL: '1.4',
  },

  body: '1.4',
  caption: '1.4',
  link: '1.4',
  overline: '1',
};
