import { SVGIconComponent } from '../types';

export const popeyesLogo: SVGIconComponent = ({ title = '', ...props }) => (
  <svg data-dir="ltr" viewBox="0 0 576 90.73" {...props}>
    <title>{title}</title>
    <path
      d="M683.09 331.12a5.45 5.45 0 105.47 5.43 5.42 5.42 0 00-5.47-5.43zm0 10.09a4.65 4.65 0 114.47-4.66 4.48 4.48 0 01-4.47 4.66z"
      transform="translate(-112.56 -251.75)"
    />
    <path
      d="M685.73 335.29c0-1.41-.91-1.9-2.52-1.9h-2.41v6.42h1.25v-2.75h.81l1.58 2.75h1.31L684 337a1.6 1.6 0 001.73-1.71zm-3.68.78v-1.69h1.08c.6 0 1.32.1 1.32.81s-.54.88-1.25.88z"
      transform="translate(-112.56 -251.75)"
    />
    <path d="M365.19 89.62h27.83V66.09L425.6 4.34h-29.56l-16.76 36.12-16.05-36.12h-29.01l30.97 61.71v23.57z" />
    <path
      d="M146.78 251.88c-10-.47-27 .46-34.22 1l.58 21.59 6.94-.44v67.25h26.73v-16.8c39.08-1.87 48.21-22.71 48.21-37.41-.02-20.85-17.02-33.72-48.24-35.19zm0 51.63v-29.38c17 .8 21.13 8.09 21.13 14 .03 8.46-5.62 15.06-21.1 15.38zM571.13 253c-25.81 0-43.68 18.73-43.68 44.53s17.23 45 46.18 45c12.06 0 22.55-2.63 31.34-8.59l-9.62-19.78a36.88 36.88 0 01-20.59 6.24c-10.66 0-17.34-5.09-19.94-13.92h56.85a71.35 71.35 0 00.89-12c-.16-18.38-9.84-41.48-41.43-41.48zm-16.58 35.41c2.08-8.6 7.56-14.47 16.67-14.47 8.68 0 15 5.16 15.68 14.47zM320.3 252.77c-9.88-.47-26.71.45-33.88 1l.57 21.37 6.87-.43v66.58h26.47v-16.65c38.69-1.84 47.73-22.47 47.73-37 0-20.64-16.83-33.42-47.76-34.87zm0 51.11v-29.09c16.87.8 20.92 8 20.92 13.89.03 8.32-5.56 14.89-20.89 15.2zM659.25 290.62c-4.77-1.92-10.51-4.05-14.25-5.75s-5-3.32-5-5.84c0-3.34 3.46-5.16 7.85-5.16 6.73 0 12 3.1 16.72 8.16l14.82-15.42c-6.36-8.56-17.27-14-31.2-14-18.86 0-33.59 9.11-33.59 27.06 0 14.18 6.83 21 20.1 26.73 3.77 1.63 8.8 3.08 12.57 4.68 3.3 1.4 5.37 2.88 5.37 5.59 0 2.5-2.2 4.54-8.57 4.54-5.88 0-14.33-2.36-20.49-6.36l-9 18.46c8.57 6.2 18.94 8.75 29.53 8.75 16.45 0 34.08-7.12 34.08-26.28.04-14.37-9.19-21.22-18.94-25.16zM242.34 253.49c-24.56 0-45 18.23-45 44.1s20.43 44.09 45 44.09 45-18.23 45-44.09-20.45-44.1-45-44.1zm.2 64.53c-11.93 0-19.5-9.17-19.5-20.6s7.57-20.61 19.5-20.61S262 286 262 297.42 254.47 318 242.54 318zM413.89 253c-25.81 0-43.68 18.73-43.68 44.53s16.83 45 46.42 45c13.23 0 24.8-3.21 34.08-10.42L440.28 313a38.5 38.5 0 01-22.52 7.38c-11.14 0-17.71-5.09-20.23-13.92h56.92a71.65 71.65 0 00.87-12c-.16-18.36-9.83-41.46-41.43-41.46zm-16.58 35.41c2.08-8.6 7.56-14.47 16.67-14.47 8.68 0 15 5.07 15.68 14.47z"
      transform="translate(-112.56 -251.75)"
    />
  </svg>
);
