import { commonIcons } from '../common/icon';
import { IIcon } from '../types/icon';

export const icon: IIcon = {
  ...commonIcons,
  bkCrown: 'bkCrown',
  desktopRaggedEdge: 'desktopRaggedEdge',
  logo: 'burgerKingLogo',
  mobileNavHome: 'mobileNavHomeBK',
  mobileRaggedEdge: 'mobileRaggedEdge',
  order: 'order',
  rewards: 'bkCrown',
};
