import { css } from 'styled-components';

/** Resets button styles and makes them more link-like */
export const buttonAsLinkReset = css`
  display: inline;
  border: none;
  background: none;
  padding: 0;
  margin: 0;

  cursor: pointer;
  color: ${(p) => p.theme.token('text-link-default')};
  font: inherit;
  text-decoration: underline;
`;
