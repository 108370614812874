/**
 * Style Primitives - USE IN CSS ONLY WHEN DESIGN TOKENS ARE NOT APPROPRIATE
 */
export const primitive = {
  // Brand-agnostic primitives go here

  // colors
  $black: '#000',
  $white: '#fff',
  $error: 'rgb(209,69,69)',
  $disabledText: 'rgba(0,0,0,0.55)',
  $disabledTextReversed: 'rgba(255,255,255,0.51)',
  $errorText: 'rgb(209, 69, 69)',
  $blackOpacity70: 'rgba(0,0,0,0.7)',
  $blackOpacity30: 'rgba(0,0,0,0.3)',
  $blackOpacity10: 'rgba(0,0,0,0.1)',
  $blackOpacity4: 'rgba(0,0,0,0.04)',
  $successText: 'rgb(56, 127, 73)',
  $warningText: 'rgb(176, 96, 0)',
  $whiteOpacity30: 'rgba(255,255,255,0.3)',
  $whiteOpacity10: 'rgba(255,255,255,0.1)',
  $whiteOpacity4: 'rgba(255,255,255,0.04)',

  $errorBackground: 'rgb(255, 231, 231)',
  $disabledBackground: 'rgba(0, 0, 0, 0.10)',
  $successBackground: 'rgb(209, 250, 229)',
  $warningBackground: 'rgb(255, 237, 213)',

  // spacing
  $spacing0: '0',
  $spacing1: '4px',
  $spacing2: '8px',
  $spacing3: '12px',
  $spacing4: '16px',
  $spacing5: '20px',
  $spacing6: '24px',
  $spacing7: '32px',
  $spacing8: '40px',
  $spacing9: '48px',
  $spacing10: '56px',
  $spacing11: '80px',

  /** Burger-King-only primitives */
  bk: {
    // colors
    $bbqBrown: 'rgb(80,35,20)',
    $mayoEggWhite: 'rgb(245,235,220)',
    $fieryRed: 'rgb(214,35,0)',
    $crunchyGreen: 'rgb(25,135,55)',
    $flamingOrange: 'rgb(255,135,50)',
    $meltyYellow: 'rgb(255,170,0)',
    $fieryRedOpacity10: 'rgba(214,35,0,0.1)',
    $fieryRedDarken10: 'rgb(163,27,0)',

    // text styles
    /** @deprecated Use the CSS variable for this font instead: var(--font-hero) */
    $hero: '2rem/2.25rem "Flame", "Helvetica Neue", Helvetica, Arial, Verdana, sans-serif',
    /** @deprecated Use the CSS variable for this font instead: var(--font-headerOne) */
    $headerOne: '1.5rem/1.5rem "Flame", "Helvetica Neue", Helvetica, Arial, Verdana, sans-serif',
    /** @deprecated Use the CSS variable for this font instead: var(--font-headerTwo) */
    $headerTwo: '1.125rem/1.25rem "Flame", "Helvetica Neue", Helvetica, Arial, Verdana, sans-serif',
    /** @deprecated Use the CSS variable for this font instead: var(--font-headerThree) */
    $headerThree:
      '1rem/1.25rem "Flame", "Cooper Black", "Helvetica Neue", Helvetica, Arial, sans-serif',
    $headerFour:
      '0.8125rem/1rem "Flame", "Cooper Black", "Helvetica Neue", Helvetica, Arial, sans-serif',
    /** @deprecated Use the CSS variable for this font instead: var(--font-copyOne) */
    $copyOne:
      '0.9375rem/1.25rem "Flame Sans", "Helvetica Neue", Helvetica, Arial, Verdana, sans-serif',
    /** @deprecated Use the CSS variable for this font instead: var(--font-copyTwo) */
    $copyTwo: '0.75rem/1rem "Flame Sans", "Helvetica Neue", Helvetica, Arial, Verdana, sans-serif',
    $formUtility:
      '0.6875rem/0.75rem "Flame Sans", "Helvetica Neue", Helvetica, Arial, Verdana, sans-serif',

    // text styles for Desktop
    /** @deprecated Use the CSS variable for this font instead: var(--font-hero) */
    $heroDesktop:
      '3.5rem/3.5rem "Flame Bold", "Helvetica Neue", Helvetica, Arial, Verdana, sans-serif',
    /** @deprecated Use the CSS variable for this font instead: var(--font-headerOne) */
    $headerOneDesktop:
      '1.875rem/2rem "Flame", "Helvetica Neue", Helvetica, Arial, Verdana, sans-serif',
    /** @deprecated Use the CSS variable for this font instead: var(--font-headerTwo) */
    $headerTwoDesktop:
      '1.5rem/1.75rem "Flame", "Helvetica Neue", Helvetica, Arial, Verdana, sans-serif',
    /** @deprecated Use the CSS variable for this font instead: var(--font-headerThree) */
    $headerThreeDesktop:
      '1.125rem/1.25rem "Flame", "Cooper Black", "Helvetica Neue", Helvetica, Arial, sans-serif',
    /** @deprecated Use the CSS variable for this font instead: var(--font-copyOne) */
    $copyOneDesktop:
      '1rem/1.5rem "Flame Sans", "Helvetica Neue", Helvetica, Arial, Verdana, sans-serif',
    /** @deprecated Use the CSS variable for this font instead: var(--font-copyTwo) */
    $copyTwoDesktop:
      '0.8125rem/1.25rem "Flame Sans", "Helvetica Neue", Helvetica, Arial, Verdana, sans-serif',
  },

  /** Popeyes-only primitives */
  plk: {
    // colors
    $nolaDark: 'rgb(31,31,31)',
    $nolaLight: 'rgb(245,241,239)',
    $popeyesOrange: 'rgb(255,125,0)',
    $burntOrange: 'rgb(242,86,0)',
    $nolaTeal: 'rgb(0,166,158)',
    $nolaPurple: 'rgb(145,25,135)',
    $nolaPurpleDark: 'rgba(88,16,82)',
    $butterYellow: 'rgb(255,200,0)',
    $spicyRed: 'rgb(217,15,6)',
    $burntOrangeOpacity10: 'rgba(242,86,0,0.1)',
    $burnOrangeDarken10: 'rgb(191,86,0)',

    // text styles
    /** @deprecated Use the CSS variable for this font instead: var(--font-hero) */
    $hero: '2rem/2.25rem "chicken_sans_bold", sans-serif',
    /** @deprecated Use the CSS variable for this font instead: var(--font-headerOne) */
    $headerOne: '1.5rem/1.75rem "chicken_sans_bold", sans-serif',
    /** @deprecated Use the CSS variable for this font instead: var(--font-headerTwo) */
    $headerTwo: '1.125rem/1.5rem "chicken_sans_bold", sans-serif',
    /** @deprecated Use the CSS variable for this font instead: var(--font-headerThree) */
    $headerThree: '1rem/1.25rem "chicken_sans_bold", sans-serif',
    $headerFour: '0.8125rem/1rem "chicken_sans_bold", sans-serif',
    /** @deprecated Use the CSS variable for this font instead: var(--font-copyOne) */
    $copyOne: '0.9375rem/1.25rem "chicken_sans", sans-serif',
    /** @deprecated Use the CSS variable for this font instead: var(--font-copyTwo) */
    $copyTwo: '0.75rem/1rem "chicken_sans", sans-serif',
    $formUtility: '0.6875rem/0.75rem "chicken_sans", sans-serif',

    // text styles for Desktop
    /** @deprecated Use the CSS variable for this font instead: var(--font-hero) */
    $heroDesktop: '3.5rem/3.5rem "chicken_sans_bold", sans-serif',
    /** @deprecated Use the CSS variable for this font instead: var(--font-headerOne) */
    $headerOneDesktop: '1.875rem/2rem "chicken_sans_bold", sans-serif',
    /** @deprecated Use the CSS variable for this font instead: var(--font-headerTwo) */
    $headerTwoDesktop: '1.5rem/1.75rem "chicken_sans_bold", sans-serif',
    /** @deprecated Use the CSS variable for this font instead: var(--font-headerThree) */
    $headerThreeDesktop: '1.125rem/1.25rem "chicken_sans_bold", sans-serif',
    /** @deprecated Use the CSS variable for this font instead: var(--font-copyOne) */
    $copyOneDesktop: '1rem/1.5rem "chicken_sans", sans-serif',
    /** @deprecated Use the CSS variable for this font instead: var(--font-copyTwo) */
    $copyTwoDesktop: '0.8125rem/1.25rem "chicken_sans", sans-serif',
  },

  fhs: {
    $fhsRed: 'rgba(196, 0, 0, 1)',
    $fhsRedDarken10: 'rgba(176, 0, 0, 1)',
    $fhsRedOpacity10: 'rgba(196, 0, 0, 0.1)',
    $fhsNavy: 'rgba(8, 13, 58, 1)',
    $fhsNavyDarken10: 'rgba(7, 12, 52, 1)',
    $houseLight: 'rgba(242, 242, 246, 1)',
    $fhsBrass: 'rgba(255, 205, 120, 1)',
    $fhsCopper: 'rgba(213, 113, 5, 1)',
    $fhsBrown: 'rgba(140, 75, 0, 1)',
    $fluoroGreen: 'rgba(240, 255, 0, 1)',
    $fhsGreen: 'rgba(46, 100, 0, 1)',
    $fhsDarkGreen: 'rgba(60, 70, 0, 1)',
    $houseRed: 'rgba(196,0,0)',
    $houseYellow: 'rgba(255,205,120)',

    // text styles
    /** @deprecated Use the CSS variable for this font instead: var(--font-hero) */
    $hero: '1.875rem/2rem Silverspoon Bold, sans-serif',
    /** @deprecated Use the CSS variable for this font instead: var(--font-headerOne) */
    $headerOne: '1.5rem/1.75rem Silverspoon Bold, sans-serif',
    /** @deprecated Use the CSS variable for this font instead: var(--font-headerTwo) */
    $headerTwo: '1.125rem/1.125rem Silverspoon Bold, sans-serif',
    /** @deprecated Use the CSS variable for this font instead: var(--font-headerThree) */
    $headerThree: '1rem/1.25rem Silverspoon Bold, sans-serif',
    /** @deprecated Use the CSS variable for this font instead: var(--font-headerFour) */
    $headerFour: '0.875rem/1rem Silverspoon Bold, sans-serif',
    /** @deprecated Use the CSS variable for this font instead: var(--font-headerFive) */
    $headerFive: '0.75rem/1rem Silverspoon Bold, sans-serif',
    /** @deprecated Use the CSS variable for this font instead: var(--font-copyOne) */
    $copyOne: '1rem/1.25rem Monserrat, sans-serif',
    /** @deprecated Use the CSS variable for this font instead: var(--font-copyTwo) */
    $copyTwo: '0.75rem/1rem Monserrat, sans-serif',
    $formUtility: '0.75rem/1.125rem Monserrat, sans-serif',

    // text styled form desktop
    /** @deprecated Use the CSS variable for this font instead: var(--font-hero) */
    $heroDesktop: '3rem/3rem Silverspoon Bold, sans-serif',
    /** @deprecated Use the CSS variable for this font instead: var(--font-headerOne) */
    $headerOneDesktop: '1.875rem/2rem Silverspoon Bold, sans-serif',
    /** @deprecated Use the CSS variable for this font instead: var(--font-headerTwo) */
    $headerTwoDesktop: '1.5rem/1.75rem Silverspoon Bold, sans-serif',
    /** @deprecated Use the CSS variable for this font instead: var(--font-headerThree) */
    $headerThreeDesktop: '1.125rem/1.25rem Silverspoon Bold, sans-serif',
    /** @deprecated Use the CSS variable for this font instead: var(--font-headerFour) */
    $headerFourDesktop: '1rem/1.125rem Silverspoon Bold, sans-serif',
    /** @deprecated Use the CSS variable for this font instead: var(--font-copyOne) */
    $copyOneDesktop: '1rem/1.25rem Monserrat, sans-serif',
    /** @deprecated Use the CSS variable for this font instead: var(--font-copyTwo) */
    $copyTwoDesktop: '0.875rem/1rem Monserrat, sans-serif',
  },
} as const;
