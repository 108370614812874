import { FC } from 'react';

import { Wrapper } from './input-message.styled';
import { IInputMessageProps } from './types';

/**
 * Show error or hint messages for form field components.
 */
const InputMessage: FC<IInputMessageProps> = ({
  id,
  hasError,
  disabled,
  children,
  disableScreenReaderAlert,
  'data-testid': testId,
  className,
}) => {
  if (!children) {
    return null;
  }

  const props = {
    id,
    $hasError: hasError,
    $disabled: disabled,
    'data-testid': testId ?? 'input-message',
    ...(hasError && !disableScreenReaderAlert && { role: 'alert' }),
    className,
  };

  return <Wrapper {...props}>{children}</Wrapper>;
};

export default InputMessage;
