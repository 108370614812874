import styled from 'styled-components';

import { IRotateIconProps } from './types';

const calculateTransitionDuration = (toDeg: number, fromDeg: number) => {
  return Math.abs(toDeg - fromDeg) / 600;
};

export const IconRotation = styled.div<Required<IRotateIconProps>>`
  transform: ${(props) =>
    props.active ? `rotate(${props.toDeg}deg)` : `rotate(${props.fromDeg}deg)`};
  transition: transform ${(props) => calculateTransitionDuration(props.toDeg, props.fromDeg)}s ease;
`;
