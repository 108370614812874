import { FC } from 'react';

import { BaseText } from './BaseText';
import { ITextProps, TextVariants } from './types';

export const H1: FC<ITextProps> = ({ children, color, align, className }) => (
  <BaseText color={color} align={align} variant={TextVariants.H1} className={className}>
    {children}
  </BaseText>
);

export const H2: FC<ITextProps> = ({ children, color, align, className }) => (
  <BaseText color={color} align={align} variant={TextVariants.H2} className={className}>
    {children}
  </BaseText>
);

export const H3: FC<ITextProps> = ({ children, color, align, className }) => (
  <BaseText color={color} align={align} variant={TextVariants.H3} className={className}>
    {children}
  </BaseText>
);

export const H4: FC<ITextProps> = ({ children, color, align, className }) => (
  <BaseText color={color} align={align} variant={TextVariants.H4} className={className}>
    {children}
  </BaseText>
);

export const H5: FC<ITextProps> = ({ children, color, align, className }) => (
  <BaseText color={color} align={align} variant={TextVariants.H5} className={className}>
    {children}
  </BaseText>
);

export const H6: FC<ITextProps> = ({ children, color, align, className }) => (
  <BaseText color={color} align={align} variant={TextVariants.H6} className={className}>
    {children}
  </BaseText>
);

export const Subtitle1: FC<ITextProps> = ({ children, color, align, className }) => (
  <BaseText color={color} align={align} variant={TextVariants.subtitle1} className={className}>
    {children}
  </BaseText>
);

export const Subtitle2: FC<ITextProps> = ({ children, color, align, className }) => (
  <BaseText color={color} align={align} variant={TextVariants.subtitle2} className={className}>
    {children}
  </BaseText>
);

export const Body1: FC<ITextProps> = ({ children, color, align, className }) => (
  <BaseText color={color} align={align} variant={TextVariants.body1} className={className}>
    {children}
  </BaseText>
);

export const Body2: FC<ITextProps> = ({ children, color, align, className }) => (
  <BaseText color={color} align={align} variant={TextVariants.body2} className={className}>
    {children}
  </BaseText>
);

export const Caption: FC<ITextProps> = ({ children, color, align, className }) => (
  <BaseText color={color} align={align} variant={TextVariants.caption} className={className}>
    {children}
  </BaseText>
);

export const Overline: FC<ITextProps> = ({ children, color, align, className }) => (
  <BaseText color={color} align={align} variant={TextVariants.overline} className={className}>
    {children}
  </BaseText>
);
