import styled, { css } from 'styled-components';

import { primitive } from '../../designTokens/primitives';
import { getColorWithOpacity } from '../../utils/get-non-transparent-color';

import { ButtonSize, RouterLinkType } from './types';

type StyledButtonProps = {
  $size: ButtonSize | undefined;
  $fullWidth: boolean;
  $hasStartIcon: boolean | undefined;
  $hasEndIcon: boolean | undefined;
  $reversed: boolean | undefined;
  $loading: boolean;
  disabled?: boolean;
  $textColor?: string;
  $bgColor?: string;
};

const BaseButton = styled<'button' | 'a' | RouterLinkType>('button')<StyledButtonProps>`
  margin: 0;
  border-radius: 999px;
  transition: background 300ms, color 300ms, width 300ms, height 300ms;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  vertical-align: middle;
  text-align: center;
  max-width: 100%;
  text-decoration: none;
  width: ${(p: { $fullWidth: boolean }) => p.$fullWidth && '100%'};
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  position: relative;

  & + & {
    margin-block: 0;
    margin-inline-start: ${(p) =>
      p.$size === 'small' ? primitive.$spacing2 : primitive.$spacing3};
    margin-inline-end: 0;
  }

  & + &[data-full-width] {
    /*
     * This relies on a data attribute selector instead of the component props
     * because of a styled-components bug that results in all buttons adopting
     * this style if even one of them had the $fullWidth prop equal true.
     * The issue: https://github.com/styled-components/styled-components/issues/3265
     */

    /*
     * Assume that the only time buttons will be siblings vertically is when
     * they are full width (ignoring the case of lines wrapping)
     */
    margin-block-start: ${primitive.$spacing2};
    margin-block-end: 0;
    margin-inline: 0;
  }

  svg {
    transition: ${(p) => p.theme.token('transition-component')};
  }

  ${(p) =>
    p.$size === 'small'
      ? css`
          font: ${p.theme.token('text-style-button-small')};
          height: ${p.theme.token('height-button-small')};
          padding: 0 ${primitive.$spacing2};

          svg {
            height: ${p.theme.token('height-icon-small')};
            width: ${p.theme.token('width-icon-small')};

            &[data-legacy-icon] {
              height: 12px;
              width: 12px;
            }
          }
        `
      : css`
          font: ${p.theme.token('text-style-button-large')};
          height: ${p.theme.token('height-button-large')};
          padding: 0 ${primitive.$spacing3};

          svg {
            height: ${p.theme.token('height-icon')};
            width: ${p.theme.token('width-icon')};

            &[data-legacy-icon] {
              height: 16px;
              width: 16px;
            }
          }
        `};

  ${(p: { $loading: boolean }) =>
    p.$loading
      ? css`
          /*
       * This is just used to avoid hover appearance. It will not prevent
       * clicking via the keyboard. The component contains logic to prevent
       * clicks when loading.
       */
          pointer-events: none;

          /* Make all descendants of the button invisible except for the loading indicator */
          & > *:not(${LoadingWrapper}) {
            opacity: 0;
          }
        `
      : css``};

  &:disabled {
    cursor: not-allowed;

    svg {
      --icon-disabled-color: ${(p) =>
        p.$reversed ? p.theme.token('icon-disabled-reversed') : p.theme.token('icon-disabled')};
      fill: var(--icon-disabled-color);
      stroke: var(--icon-disabled-color);
    }
  }

  --box-shadow: ${(p) =>
    p.$reversed
      ? p.theme.token('box-shadow-pattern-reversed')
      : p.theme.token('box-shadow-pattern')};

  &:focus {
    outline: none;
    box-shadow: var(--box-shadow);
  }

  &:not(:focus-visible) {
    box-shadow: none;
  }

  &:focus-visible {
    box-shadow: var(--box-shadow);
  }

  &:active:not(:disabled) {
    box-shadow: var(--box-shadow);
  }
`;

export const PrimaryButtonStyle = styled(BaseButton)<StyledButtonProps>`
  color: ${(p) =>
    p.$textColor ||
    (p.$reversed
      ? p.theme.token('text-button-primary-reversed')
      : p.theme.token('text-button-primary'))};

  background: ${(p) =>
    p.$bgColor ||
    (p.$reversed
      ? p.theme.token('background-button-primary-default-reversed')
      : p.theme.token('background-button-primary-default'))};

  border: none;

  svg {
    --icon-color: ${(p) =>
      p.$reversed
        ? p.theme.token('icon-button-primary-reversed')
        : p.theme.token('icon-button-primary')};
    fill: var(--icon-color);
    stroke: var(--icon-color);
  }

  &:hover {
    background: ${(p) => {
      if (p.$bgColor) {
        return getColorWithOpacity(p.$bgColor, 0.8);
      }
      return p.$reversed
        ? p.theme.token('background-button-hover-reversed')
        : p.theme.token('background-button-primary-hover');
    }};
    ${(p) =>
      p.$reversed &&
      !p.$textColor &&
      css`
        color: ${p.theme.token('text-button-primary')};

        svg {
          fill: ${p.theme.token('icon-button-primary')};
          stroke: ${p.theme.token('icon-button-primary')};
        }
      `};
  }

  &:disabled {
    background: transparent;
    color: ${(p) =>
      p.$reversed ? p.theme.token('text-disabled-reversed') : p.theme.token('text-disabled')};
    border-width: ${(p) => p.theme.token('border-width-button-secondary')};
    border-color: ${(p) =>
      p.$reversed
        ? p.theme.token('border-color-disabled-reversed')
        : p.theme.token('border-color-disabled')};
    border-style: solid;
  }
`;

export const SecondaryButtonStyle = styled(BaseButton)<StyledButtonProps>`
  background: transparent;
  border-width: ${(p) => p.theme.token('border-width-button-secondary')};
  border-color: ${(p) =>
    p.$reversed
      ? p.theme.token('border-color-button-secondary-reversed')
      : p.theme.token('border-color-button-secondary')};
  border-style: solid;
  color: ${(p) =>
    p.$reversed ? p.theme.token('text-button-reversed') : p.theme.token('text-button-secondary')};

  svg {
    --icon-color: ${(p) =>
      p.$reversed ? p.theme.token('icon-button-reversed') : p.theme.token('icon-button-secondary')};
    fill: var(--icon-color);
    stroke: var(--icon-color);
  }

  &:hover {
    background: ${(p) =>
      p.$reversed
        ? p.theme.token('background-button-hover-reversed')
        : p.theme.token('background-button-secondary-hover')};
  }

  &:disabled {
    /* Override the hover effect */
    background: transparent;

    color: ${(p) =>
      p.$reversed ? p.theme.token('text-disabled-reversed') : p.theme.token('text-disabled')};
    border-color: ${(p) =>
      p.$reversed
        ? p.theme.token('border-color-disabled-reversed')
        : p.theme.token('border-color-disabled')};
  }
`;

export const TertiaryButtonStyle = styled(BaseButton)<StyledButtonProps>`
  border: none;
  background: transparent;
  color: ${(p) =>
    p.$reversed
      ? p.theme.token('text-button-reversed')
      : p.theme.token('text-button-tertiary-default')};

  svg {
    --icon-color: ${(p) =>
      p.$reversed
        ? p.theme.token('icon-button-reversed')
        : p.theme.token('icon-button-tertiary-default')};
    fill: var(--icon-color);
    stroke: var(--icon-color);
  }

  &:hover:not(:disabled) {
    ${(p) =>
      p.$reversed
        ? css`
            background: ${p.theme.token('background-button-hover-reversed')};
          `
        : css`
            color: ${p.theme.token('text-button-tertiary-hover')};

            svg {
              fill: ${p.theme.token('icon-button-tertiary-hover')};
              stroke: ${p.theme.token('icon-button-tertiary-hover')};
            }
          `}
  }

  &:disabled {
    color: ${(p) =>
      p.$reversed ? p.theme.token('text-disabled-reversed') : p.theme.token('text-disabled')};
  }
`;

export const IconOnlyButtonStyle = styled(SecondaryButtonStyle)<StyledButtonProps>`
  svg {
    height: ${(p) => p.theme.token('height-icon-small')};
    width: ${(p) => p.theme.token('width-icon-small')};
  }

  ${(p) =>
    p.$size === 'small'
      ? css`
          height: 24px;
          padding: 0 calc(4px - ${p.theme.token('border-width-button-secondary')});
        `
      : css`
          height: 40px;
          padding: 0 calc(12px - ${p.theme.token('border-width-button-secondary')});
        `};
`;

export const TextWrapper = styled.span`
  /*
   * We technically shouldn't need extra vertical padding on the text, but this
   * keeps low-hanging letters like "g" in the font from getting cut off by
   * overflow: hidden;. It doesn't affect the appearance otherwise, thanks to
   * the flexbox alignment.
   */
  padding: ${primitive.$spacing1} ${primitive.$spacing2};
  flex: 1 1 auto;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const LoadingWrapper = styled.div`
  position: absolute;
  inset-inline-start: 0;
  inset-inline-end: 0;
`;
