import styled from 'styled-components';

import { primitive } from '../../designTokens/primitives';
import { mediaQuery } from '../../globalStyles';

import {
  COLUMNS_DESKTOP_LARGE,
  COLUMNS_DESKTOP_SMALL,
  COLUMNS_MOBILE,
  COLUMNS_TABLET,
  MAX_WIDTH_DESKTOP_LARGE,
} from './constants';

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(${COLUMNS_MOBILE}, 1fr);
  column-gap: ${primitive.$spacing4};
  margin: 0 6%;

  & > * {
    min-width: 0;
  }

  @media ${mediaQuery.tablet} {
    grid-template-columns: repeat(${COLUMNS_TABLET}, 1fr);
  }

  @media ${mediaQuery.desktopSmall} {
    grid-template-columns: repeat(${COLUMNS_DESKTOP_SMALL}, 1fr);
  }

  @media ${mediaQuery.desktopLarge} {
    grid-template-columns: repeat(${COLUMNS_DESKTOP_LARGE}, 1fr);
    max-width: ${MAX_WIDTH_DESKTOP_LARGE}px;
    margin: 0 auto;
  }
`;
