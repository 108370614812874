import { StyledCell } from './cell.styled';
import { ICellProps } from './types';

const Cell: React.FC<ICellProps> = ({ children, style, className, ...otherProps }) => {
  // Props are prefixed with `$` in order to avoid them being directly
  // applied to the underlying HTML element
  const styledProps = Object.entries(otherProps).reduce((acc, [propName, value]) => {
    acc[`$${propName}`] = value;
    return acc;
  }, {});

  return (
    <StyledCell style={style} className={className} {...styledProps}>
      {children}
    </StyledCell>
  );
};

export default Cell;
