import { ReactNode } from 'react';

import { IStyleable } from '../types';

export enum TextVariants {
  H1 = 'H1 Headline',
  H2 = 'H2 Headline',
  H3 = 'H3 Headline',
  H4 = 'H4 Headline',
  H5 = 'H5 Headline',
  H6 = 'H6 Headline',
  subtitle1 = 'Subtitle 1',
  subtitle2 = 'Subtitle 2',
  body1 = 'Body 1',
  body2 = 'Body 2',
  caption = 'Caption',
  overline = 'Overline',
}

export interface IBaseTextProps extends IStyleable {
  color?: string;
  align?: string;
  variant: TextVariants;
  children?: ReactNode;
}

export type ITextProps = Omit<IBaseTextProps, 'variant'>;
