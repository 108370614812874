import { SVGIconComponent } from '../types';

export const mobileNavHomeBK: SVGIconComponent = ({ title = '', ...props }) => (
  <svg viewBox="0 0 17 19" {...props}>
    <title>{title}</title>
    <g fillRule="evenodd">
      <path d="M15.987 18.906c0-.057-2.786.078-3.022.086-.404.017-.812.004-1.216-.01-.753-.031-1.516-.018-2.265-.014-.58.003-1.153-.06-1.731-.038-1.325.049-2.643-.027-3.968-.027-.591.002-1.267 0-1.855.003-.652.005-1.243.067-1.895 0-.03-.003-.049-1.764-.023-1.922.02-.13.113-1.936.023-1.938 1.54.072 3.101.038 4.646.046 1.726.01 3.452.002 5.175-.069 2.044-.088 4.088.111 6.13.023-.054.001-.024 2.15-.022 2.336.001.188.004.373.023.562.03.32 0 .641 0 .962M16 3.15c0 .04-6.553.793-6.712.781-2.437-.172-.884-.003-3.328 0-.368 0-5.956-.037-5.96.058V.027c0-.084 8.092.058 8.827.058 1.165 0 2.327-.006 3.491.032C12.562.124 16 .128 16 .193V3.15zM5.787 9.462c1.97-.193 3.918-.41 4.297-.448 1.724-.176 3.458-.34 5.186-.485.088-.007 1.667-.136 1.667-.14a24.905 24.905 0 00-.215-1.958c-.034-.24-.017-.49-.028-.732-.006-.134.034-1.671-.043-1.685-.031-.007-7.767.74-8.388.81-.603.068-7.253.663-7.263.501l.268 4.094c.542-.006 2.557.016 4.519.043M16 13.949c-.99 0-2.029.013-3.014.042-1.624.044-3.444-.084-5.135-.147-.638-.024-7.8.136-7.807.105-.108-.658.019-1.296.006-1.946-.003-.206-.035-.42-.04-.627-.006-.285.034-.55.034-.838v-.534c0-.027 8.326.08 9.02.08h.338a45.8 45.8 0 012.51.048c.878.048 1.761-.128 2.635-.128H16v3.945z" />
    </g>
  </svg>
);
