import { SVGIconComponent } from '../types';

export const drinks: SVGIconComponent = ({ title = '', ...props }) => (
  <svg data-legacy-icon viewBox="0 0 38 70" {...props}>
    <title>{title}</title>
    <path
      d="M2.2 20H35.8a2 2 0 0 1 1.991 2.189l-4.363 46A2 2 0 0 1 31.438 70H6.573a2 2 0 0 1-1.99-1.81l-4.375-46A2 2 0 0 1 2.2 20zM17 17L23.827.615A1 1 0 0 1 24.75 0h8.75a.5.5 0 0 1 .5.5v2.09a.5.5 0 0 1-.5.5h-7.485a.5.5 0 0 0-.464.316L20.123 17H17z"
      fillRule="evenodd"
    />
  </svg>
);
