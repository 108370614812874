import { IconRotation } from './rotate-icon.styles';
import { IRotateIconProps } from './types';

export function RotateIcon({ active, children, fromDeg = 0, toDeg = 180 }: IRotateIconProps) {
  return (
    <IconRotation active={active} fromDeg={fromDeg} toDeg={toDeg}>
      {children}
    </IconRotation>
  );
}
