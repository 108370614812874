import styled, { css } from 'styled-components';

import { mediaQuery } from '../../globalStyles';
import {
  COLUMNS_DESKTOP_LARGE,
  COLUMNS_DESKTOP_SMALL,
  COLUMNS_MOBILE,
  COLUMNS_TABLET,
} from '../grid/constants';

import { ICellProps } from './types';

export const StyledCell = styled.div<{ [k in keyof ICellProps as `$${k}`]: ICellProps[k] }>`
  ${(props) => {
    let {
      $span,
      $spanTablet,
      $spanDesktopSmall,
      $spanDesktopLarge,
      $start,
      $startTablet,
      $startDesktopSmall,
      $startDesktopLarge,
      $row,
      $rowTablet,
      $rowDesktopSmall,
      $rowDesktopLarge,
    } = props;

    // Mobile
    $span = Math.min($span ?? COLUMNS_MOBILE, COLUMNS_MOBILE);
    $start = $start !== undefined ? Math.min($start, COLUMNS_MOBILE - $span + 1) : undefined;

    const scaleUp = (baseColumnCount: number, targetColumnCount: number) => (baseValue: number) =>
      Math.round((targetColumnCount / baseColumnCount) * baseValue);

    // Tablet
    const mobile2Tablet = scaleUp(COLUMNS_MOBILE, COLUMNS_TABLET);
    $spanTablet = Math.min($spanTablet ?? mobile2Tablet($span), COLUMNS_TABLET);
    if ($startTablet !== undefined) {
      $startTablet = Math.min($startTablet, COLUMNS_TABLET - $spanTablet + 1);
    } else if ($start !== undefined) {
      $startTablet = Math.min(mobile2Tablet($start - 1) + 1, COLUMNS_TABLET - $spanTablet + 1);
    }

    // Desktop small
    $spanDesktopSmall = Math.min($spanDesktopSmall ?? $spanTablet, COLUMNS_DESKTOP_SMALL);
    if ($startDesktopSmall !== undefined) {
      $startDesktopSmall = Math.min(
        $startDesktopSmall,
        COLUMNS_DESKTOP_SMALL - $spanDesktopSmall + 1,
      );
    } else if ($startTablet !== undefined) {
      $startDesktopSmall = Math.min($startTablet, COLUMNS_DESKTOP_SMALL - $spanDesktopSmall + 1);
    }

    // Desktop Large
    const desktopSmall2DesktopLarge = scaleUp(COLUMNS_DESKTOP_SMALL, COLUMNS_DESKTOP_LARGE);
    $spanDesktopLarge = Math.min(
      $spanDesktopLarge ?? desktopSmall2DesktopLarge($spanDesktopSmall),
      COLUMNS_DESKTOP_LARGE,
    );
    if ($startDesktopLarge !== undefined) {
      $startDesktopLarge = Math.min(
        $startDesktopLarge,
        COLUMNS_DESKTOP_LARGE - $spanDesktopLarge + 1,
      );
    } else if ($startDesktopSmall !== undefined) {
      $startDesktopLarge = Math.min(
        desktopSmall2DesktopLarge($startDesktopSmall - 1) + 1,
        COLUMNS_DESKTOP_LARGE - $spanDesktopLarge + 1,
      );
    }
    $rowTablet ??= $row;
    $rowDesktopSmall ??= $rowTablet;
    $rowDesktopLarge ??= $rowDesktopSmall;

    return css`
      grid-column-end: span ${$span};
      grid-column-start: ${$start};
      grid-row-start: ${$row};

      @media ${mediaQuery.tablet} {
        grid-column-end: span ${$spanTablet};
        grid-column-start: ${$startTablet};
        grid-row-start: ${$rowTablet};
      }

      @media ${mediaQuery.desktopSmall} {
        grid-column-end: span ${$spanDesktopSmall};
        grid-column-start: ${$startDesktopSmall};
        grid-row-start: ${$rowDesktopSmall};
      }

      @media ${mediaQuery.desktopLarge} {
        grid-column-end: span ${$spanDesktopLarge};
        grid-column-start: ${$startDesktopLarge};
        grid-row-start: ${$rowDesktopLarge};
      }
    `;
  }}
`;
