import { SVGIconComponent } from '../types';

export const signatureChicken: SVGIconComponent = ({ title = '', ...props }) => (
  <svg data-legacy-icon viewBox="0 0 72 62" {...props}>
    <title>{title}</title>
    <path
      d="M18.157.013c.45-.039 2.777.017 3.27.077 2.445.313 4.49.943 6.538 2.02 1.404.738 3.18 1.968 4.574 3.17.67.578 2.371 2.345 3.013 3.126 2.405 2.933 4.317 6.124 7.54 12.574 2.314 4.632 2.757 5.499 3.614 7.098 1.411 2.638 2.72 4.825 4.044 6.747.285.42.596.904.683 1.072.687 1.32.753 2.877.182 4.297-.178.437-.41.806-1.71 2.693-.807 1.171-1.034 1.471-1.349 1.78a4.599 4.599 0 0 1-3.067 1.325c-.712.03-1.014-.017-2.18-.36-3.71-1.08-6.888-1.694-14.33-2.758-4.221-.604-6.075-.9-8.09-1.29-4.76-.922-8.345-2.18-11.093-3.89-1.717-1.068-2.769-1.896-4.006-3.157-2.223-2.26-3.783-4.859-4.772-7.921a20.953 20.953 0 0 1 .356-13.913c.26-.69.94-2.131 1.308-2.779 1.34-2.35 3.058-4.353 5.107-5.948A19.166 19.166 0 0 1 14.41.652c1.225-.335 2.202-.502 3.746-.64zm37.591 42.006a2.924 2.924 0 0 1 1.032.072c.453.133.784.352 1.317.875 1.178 1.158 4.68 4.503 4.71 4.503a.892.892 0 0 0 .159-.206c.18-.291.746-.883 1.11-1.162a4.834 4.834 0 0 1 2.978-.99c1.338 0 2.538.493 3.444 1.415 1.308 1.329 1.828 3.43 1.295 5.201-.39 1.291-1.425 2.513-2.587 3.058-.805.382-.877.42-1.128.682a2.27 2.27 0 0 0-.646 1.595c0 .875-.31 1.84-.851 2.672-.294.45-1.061 1.209-1.506 1.492-1.489.94-3.292 1.03-4.827.24-.872-.446-1.497-1.016-2.034-1.857-.985-1.535-1.04-3.64-.138-5.236.084-.154.184-.326.218-.386.033-.06.084-.137.113-.18.067-.081.05-.094-3.703-2.028-1.405-.725-2.659-1.394-2.78-1.488-.894-.682-1.187-2.029-.667-3.08.214-.428 2.822-4.185 3.111-4.48a2.595 2.595 0 0 1 1.38-.712z"
      fillRule="evenodd"
    />
  </svg>
);
