import { SVGIconComponent } from '../types';

export const list: SVGIconComponent = ({ title = '', ...props }) => (
  <svg viewBox="0 0 24 24" {...props}>
    <title>{title}</title>
    <path d="M2 12.6667H4.22222V10.4444H2V12.6667ZM2 17.1111H4.22222V14.8889H2V17.1111ZM2 8.22222H4.22222V6H2V8.22222ZM6.44444 12.6667H22V10.4444H6.44444V12.6667ZM6.44444 17.1111H22V14.8889H6.44444V17.1111ZM6.44444 6V8.22222H22V6H6.44444ZM2 12.6667H4.22222V10.4444H2V12.6667ZM2 17.1111H4.22222V14.8889H2V17.1111ZM2 8.22222H4.22222V6H2V8.22222ZM6.44444 12.6667H22V10.4444H6.44444V12.6667ZM6.44444 17.1111H22V14.8889H6.44444V17.1111ZM6.44444 6V8.22222H22V6H6.44444Z" />
  </svg>
);

export const listAlt: SVGIconComponent = ({ title = '', ...props }) => (
  <svg fill="none" viewBox="0 0 24 24" {...props}>
    <path d="M19 5v14H5V5h14zm1.1-2H3.9c-.5 0-.9.4-.9.9v16.2c0 .4.4.9.9.9h16.2c.4 0 .9-.5.9-.9V3.9c0-.5-.5-.9-.9-.9zM11 7h6v2h-6V7zm0 4h6v2h-6v-2zm0 4h6v2h-6v-2zM7 7h2v2H7V7zm0 4h2v2H7v-2zm0 4h2v2H7v-2z" />
  </svg>
);
