import { SVGIconComponent } from '../types';

export const catering: SVGIconComponent = ({ title = '', ...props }) => (
  <svg data-legacy-icon viewBox="0 0 90 72" {...props}>
    <title>{title}</title>
    <path
      d="M18 0c9.941 0 18 8.06 18 18s-8.059 18-18 18S0 27.94 0 18 8.059 0 18 0zm32.963 44.106H69c-1.863 3.436-10.979 5.007-10.979 5.007l-11.37 15.12s-.98 1.561-3.072 1.734l.033.033H4s2.992-27 12.556-27c9.566 0 26.86 14.923 26.86 14.923l7.547-9.72v-.097zM61.315 22h1.142c13.581 0 16.206 13.73 16.206 13.73 1.712 0 4.109.432 4.337 3.243C83 41.676 79.12 42 79.12 42H44.88S41 41.676 41 38.973c0-2.703 2.397-3.243 4.109-3.243 0 0 2.625-13.73 16.206-13.73zm2.749-5c.515 0 .936.45.936 1s-.42 1-.936 1h-6.126c-.516 0-.938-.45-.938-1s.422-1 .938-1h6.126z"
      fillRule="evenodd"
    />
  </svg>
);

export const cateringPLK: SVGIconComponent = ({ title = '', ...props }) => (
  <svg data-legacy-icon viewBox="0 0 90 72" {...props}>
    <title>{title}</title>
    <path
      d="M24.75,28.08c3.75,3,1.13,9.17,0.78,12.72c-0.17,1.71-0.11,3.29,0.12,4.75c0.5,2.03,2.73,1.01,12.67,1.31
      c7.54,0.23,15.11-0.78,22.63,0.13c2.04,0.25,6-0.73,6.39-2.76c0.01-0.03,0.01-0.06,0.02-0.09c0-0.02,0.01-0.04,0.01-0.07
      c0.59-3.23,0.29-6.9-1.28-10.99c-2.89-7.56-9.01-18.84-9.01-18.84l0.07-0.02c2.42-0.62,2.89-3.76,0.06-5.45l2.02-1.87l-2.96-1.21
      l1.1-4.04l-6.35,2.52c0.01-0.01,0.02-0.01,0.02-0.01S49.45,0.79,47,0.76c-2.06-0.02-3.04,1.47-3.04,1.47S43.41,0.7,42,0.28
      s-3.71,0.4-4.01,2.96c-0.33,2.87,1.99,4.35,2.76,4.59c0.91,0.29,2.16,0.87,4.78-0.23c0,2.64-0.01,8.74-0.02,11.81
      c-0.01,3.98,0.35,10.43-1.36,13.93s-3.66,4.96-6.23,4.65c-2.88-0.34-3.87-3.03-2.87-8.04s1.85-9.98-2.35-13.69
      s-11.75-4.12-20.46,6.4l5.7,6.88C17.95,29.55,20.99,25.07,24.75,28.08z M51.59,6.6c0.65-0.33,1.48-0.01,1.85,0.72
      s0.15,1.59-0.5,1.92s-1.48,0.01-1.85-0.72C50.72,7.79,50.94,6.93,51.59,6.6z"
    />
    <path
      d="M73.23,49.46c-0.53-0.48-1.11-0.69-1.75-0.72c-0.53-0.02-1.06-0.09-1.6-0.12c-1.22-0.06-2.19-0.36-3.75-0.26
			c-1.08,0.07-2.16,0.08-3.24,0.1c-0.69,0.01-1.39-0.02-2.06,0c-0.76,0.03-1.51-0.09-2.26-0.07c-0.83,0.02-1.64,0.28-2.46,0.24
			c-0.9-0.05-1.75-0.33-2.65-0.37c-0.66-0.03-1.32-0.03-1.98-0.02c-3.48,0.03-6.96,0.07-10.44,0.1c-3.31,0-6.61,0.26-9.92,0.02
			c-0.41-0.03-0.83-0.07-1.24,0c-1.35,0.22-2.74,0.17-4.11,0.21c-0.61,0.02-1.22,0.04-1.84,0.07c-0.25,0.01-0.78,0.18-1.01,0.11
			c-1.31-0.38-4.79,0.59-3.02,2.31c0.52,0.51,1.1,0.62,1.7,0.53c0.16,0.43,0.37,1,0.42,1.18c0.6,2.44,2.16,2,2.61,2.24
			c0.01,0.01,1.62-0.02,3.22-0.06c-0.17,0.86,0.25,1.57,1.2,1.71c0.34,0.05,0.88,0.11,1.51,0.2c1.61,0.37,4.06,1.16,5.16,2.12
			c0.2,0.26,0.42,0.51,0.66,0.76c0.18,0.23,0.37,0.47,0.55,0.69c0.39,0.49,0.77,0.97,1.15,1.48c0.25,0.33-0.48,1.65-0.94,2.19
			l-0.27,0.31c-0.66,0.38-1.32,0.76-1.96,1.27c-0.97,0.78-2.31,0.98-3.5,1.33c-0.17,0.05-0.33,0.15-0.46,0.26
			c-0.97,0.87-2.21,1.1-3.45,1.17c-1.59,0.08-2.96,0.79-4.64,0.34c-0.15-0.04-0.4,0.25-0.41,0.45c0,0.2,0.21,0.52,0.4,0.72
			c0.67,0.69,1.42,0.75,2.04,0.53c1.73-0.63,3.65-0.85,5.6-1.14c0.71-0.11,1.39-0.33,2.08-0.55c0.63-0.19,1.25-0.4,1.87-0.63
			c1.2-0.54,2.42-0.98,3.6-1.59c0.83-0.45,1.68-0.89,2.07-1.43c0.15-0.22,0.24-0.42,0.31-0.59c0.05,0.02,0.1,0.04,0.14,0.07
			c0.2,0.66,0.58,1.28,1.22,1.74c1.59,1.14,3.1,1.73,4.11,2.23c-0.03,0.03-0.06,0.06-0.1,0.09c-0.86,0.9-2.16,1.27-3.3,1.77
			c-0.12,0.05-0.23,0.14-0.32,0.23c0.1,1.01,0.98,1.3,2.1,1.19c0.47-0.22,0.93-0.44,1.39-0.68c0.83-0.52,3.13-1.88,3.36-2.04
			c0.76-0.56,1.55-1.1,1.87-1.68c0.55-1.12,0.14-1.6-0.38-1.77c0.84-1.66,2.04-3.57,4.58-3.14c2.68,0.45,5.35,0.69,7.37,0.85
			c1.77,0.14,3.39-0.51,4.67-1.89c0.18-0.19,0.37-0.4,0.51-0.61c0.03-0.04,0.06-0.08,0.08-0.13c0.23-0.4,0.27-0.83-0.2-1.32
			c-1.02-1.05-2.77-0.27-3.28-0.13c-0.52,0.13-2.8,0.33-3.82,0.41c-0.45,0.04-2.29-0.26-3.72-0.51c0.54-0.22,2.25-0.48,2.88-0.76
			c0.51-0.22,1.49-1.27,2.16-2.34c0.19-0.24,0.33-0.49,0.4-0.74c0.12-0.27,0.2-0.53,0.24-0.76c1.22,0.03,2.43,0.03,3.65,0.13
			c0.83,0.07,3.23,0.15,4.44-2.43c0-0.01,0.01-0.03,0.01-0.04c0.16-0.33,0.32-0.77,0.49-1.26C74.24,50.84,73.44,49.64,73.23,49.46z
			 M25.7,53.36c-0.98,0.12-1.8-1.01-2.03-1.97c-0.02-0.07-0.03-0.13-0.05-0.19c0.37-0.09,0.75-0.19,1.11-0.34
			c0.83-0.34,1.62-0.25,2.38,0.04c1.35,0.51,2.73,0.55,4.11,0.71c1.26,0.15,2.53-0.14,3.8-0.05c0.84,0.05,1.7,0.07,2.53-0.13
			c2.19-0.54,3.96-0.61,6.15-0.57c0.27,0.01,0.53,0.12,0.8,0.17c0.76,0.14,2.12-0.45,2.88-0.42c2,0.08,4-0.01,6,0.11
			c1.8,0.11,3.34,0.63,5.1,0.26c0.11-0.02,0.22-0.1,0.33-0.17c0.51-0.36,1.03-0.36,1.58-0.25c0.16,0.03,0.31,0.06,0.47,0.08
			c-0.02,0-0.04,0-0.06,0.01c-0.1,0.01-0.2,0.01-0.3,0.03c-1.85,0.38-3.66,1.15-5.34,2.09c-0.35,0.02-0.69,0.03-1.04,0.04
			c-1.27,0.04-2.54,0.06-3.8,0.11c-0.66,0.02-1.31,0.14-1.97,0.12c-0.78-0.02-1.55,0.07-2.31,0.23c-0.63,0.13-1.26,0.22-1.91,0.08
			c-0.36-0.08-0.96-0.08-1.33-0.07c-0.8,0.03-1.6,0.03-2.39-0.25c-0.34-0.12-4.77,0.09-5.62,0.12c-0.36-0.19-0.72-0.35-1.1-0.48
			c-1.16-0.53-2.39-0.72-3.65-0.14c-0.12,0.06-0.55,0.34-0.99,0.72c-0.17,0-0.29,0-0.35-0.01C27.4,53.17,26.43,53.27,25.7,53.36z
			 M52.08,54.75c-0.86,0.63-1.66,1.27-2.35,1.87c-0.15,0.13-0.3,0.27-0.45,0.41c-0.76,0.69-1.55,1.4-2.66,1.37
			c-2.45-0.09-4.99-0.18-6.91-1.97c-0.64-0.6-1.21-0.93-1.81-1.29c-0.03-0.02-0.06-0.03-0.08-0.05c0.29,0,0.58,0,0.86,0
			c0.42,0,0.84,0.04,1.26,0.06c0.54,0.02,1.1,0.18,1.6,0.04c1.93-0.52,3.89-0.34,5.84-0.33C48.03,54.86,49.95,54.81,52.08,54.75z
			 M50.4,60.47c2.9-1.2,7.69-4.73,10.81-6.06c0.01,0.15,0.03,0.28,0.05,0.39c-0.3,0.57-1.03,1.39-1.74,2.07
			c-0.48,0.32-0.97,0.59-1.34,0.75c-0.4,0.17-1.47,0.49-2.32,0.76c-1.79,0.55-1.79,0.55-1.82,0.89c0,0,0,0.35,0.2,0.42
			c0.22,0.08,0.74,0.15,1.24,0.23c0.23,0.04,0.46,0.08,0.69,0.12c1.24,0.28,2.36,0.46,3.38,0.53c0.43,0.05,0.76,0.07,0.95,0.06
			c0.12-0.01,0.23-0.02,0.34-0.03c0.24-0.01,0.47-0.02,0.71-0.04c0.46-0.03,0.88-0.05,1.49-0.13c0,0,1.26-0.16,1.48-0.25
			c0.85-0.36,1.79-0.69,2.37-0.1c0.11,0.11,0.15,0.17,0.01,0.36c-0.05,0.07-0.11,0.14-0.17,0.2c-0.01,0.02-0.02,0.03-0.04,0.04
			c-0.3,0.32-0.66,0.58-0.97,0.79c-1.83,1.21-4.15,0.85-6.21,0.36c-1.24-0.3-2.52-0.49-3.8-0.59c-3.46-0.27-5.88,1.2-7.1,4.4
			c-0.82,0.83-5.24-1.48-7.79-2.21c-0.57-0.16-1.03-0.56-1.26-1.1c-0.18-0.42-0.41-0.92-0.64-1.28c-1.85-2.93-4.34-4.78-7.82-4.97
			c-0.64-0.16-1.16-0.26-1.39-0.3c-0.18-0.03-0.46-0.07-0.63-0.11c-0.05-0.03-0.1-0.07-0.14-0.1c0.01-0.06,0.03-0.17,0.11-0.38
			c0.19-0.49,0.95-1.11,1.41-1.43c0.95-0.18,2.08-0.02,2.85,0.26c0.9,0.33,1.71,0.47,2.45,0.76c0.14,0.1,0.28,0.19,0.41,0.29
			c0.43,0.31,0.81,0.54,1.19,0.76c0.02,0.02,0.04,0.04,0.07,0.07c1.56,1.74,4.84,4.04,6.32,4.24c3.6,0.49,3.9-0.49,5.58-1.6
			c3.35-2.21,6.66-4.47,10.01-6.68c0.44-0.14,0.88-0.26,1.32-0.35c0.37-0.08,0.75-0.06,1.14,0.02c0.34,0.12,0.66,0.31,0.93,0.57
			C57.12,56.42,50.27,58.31,50.4,60.47z M68.04,51.49c-0.05,0.16-0.11,0.32-0.17,0.48c-0.36,0.97-1.34,1.56-2.36,1.43h-0.02
			c-0.76-0.1-1.51-0.17-2.27-0.24c0.02-0.02,0.04-0.04,0.06-0.07c0.4-0.35,0.67-0.68,0.63-1.1c-0.04-0.46-0.44-0.73-0.79-0.91
			c-0.12-0.09-0.26-0.18-0.41-0.27c0.75,0.02,1.5-0.03,2.25-0.19c0.42-0.09,0.83-0.12,1.23,0.16c0.23,0.16,0.52,0.21,0.78,0.22
			c0.42,0.01,0.84,0,1.26-0.01C68.16,51.15,68.1,51.32,68.04,51.49z"
    />
    <path d="M23.62 59.35h.56v1.67h.26v-1.67H25v-.23h-1.38zM26.19 60.48l-.6-1.36h-.33v1.9h.25v-1.53l.56 1.27h.24l.55-1.27v1.53h.25v-1.9h-.33z" />
  </svg>
);
